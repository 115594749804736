import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { finalize } from "rxjs/operators";
import { IntegrationsService } from "@app/modules/site-settings-integrations/services/integrations.service";
import * as moment from 'moment';

@Component({
    selector: 'app-integration-logs-dialog',
    templateUrl: './integration-logs-dialog.component.html',
    styleUrls: ['./integration-logs-dialog.component.scss']
})
export class IntegrationLogsDialogComponent implements OnInit {

    integrationEvent: any;
    isLoading: boolean = false;
    payload: any;
    mergedMessages: string = '';

    constructor(
        private dialogRef: MatDialogRef<IntegrationLogsDialogComponent>,
        private integrationService: IntegrationsService,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.integrationEvent = data.integrationEvent;
        this.payload = JSON.parse(this.integrationEvent.payload);
    }

    ngOnInit() {
        this.getData();
    }

    confirm() {
        this.dialogRef.close(true);
    }

    close() {
        this.dialogRef.close();
    }

    getPrettyJson(json: any) {
        return JSON.stringify(json, null, 2);
    }

    mergeMessages(data: any[]): string {
        const sortedData = data.sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime());
        return sortedData.map(item => {
            const timestamp = moment(item.createdOn).format('YYYY-MM-DD HH:mm:ss');
            return `${timestamp}: ${item.message}`;
        }).join('\n\n');
    }

    getData() {
        this.isLoading = true;

        this.integrationService.getIntegrationEventLogs(this.integrationEvent.id, 0 , 100)
        .pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
        .subscribe(res => {
            this.mergedMessages = this.mergeMessages(res.data);
        })
    }
}
