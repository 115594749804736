

<ng-container *ngIf="loadingTableField; else LoadedDbTableField">
    <ngx-skeleton-loader
        count="1"
        [theme]="{
            width: '300px',
            height: '50px'
        }"
    ></ngx-skeleton-loader>
</ng-container>

<ng-template #LoadedDbTableField>
    <div [formGroup]="parentGroup" *ngIf="formElement.hidden !== 'true'">
        <!-- Text Field -->
        <mat-form-field *ngIf="formElement.formElementType.id === fieldTypes.TEXT_INPUT" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <input matInput type="text" [formControlName]="formElement.formControl" [placeholder]="formElement.placeholder">

            <div class="error-text" *ngIf="formControl.errors?.minlength">
                {{ 'MustBeAtLeast' | translate: {Default: "Must be at least"} }} {{formElement.minLength}} {{ 'CharactersLong' | translate: {Default: "characters long"} }}
            </div>
            <div class="error-text" *ngIf="formControl.errors?.maxlength">
                {{ 'CannotBeMoreThan' | translate: {Default: "Cannot be more than"} }} {{formElement.maxLength}} {{ 'CharactersLong' | translate: {Default: "characters long"} }}
            </div>
        </mat-form-field>

        <!-- Password Input -->
        <mat-form-field *ngIf="formElement.formElementType.id === fieldTypes.PASSWORD_INPUT" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <input matInput [type]="showPassword ? 'text' : 'password'" [formControlName]="formElement.formControl" [placeholder]="formElement.placeholder">
            <mat-icon
                setColor
                [setColorRules]="[
                    {
                        color: 'primaryColour',
                        events: ['hover']
                    }
                ]"
                class="material-icons-outlined clickable-icon"
                matSuffix
                (click)="toggleShowPassword()"
                [matTooltip]="translate.instant('ShowPassword')"
                *ngIf="!showPassword"
            >visibility</mat-icon>
            <mat-icon
                setColor
                [setColorRules]="[
                    {
                        color: 'primaryColour',
                        events: ['hover']
                    }
                ]"
                class="material-icons-outlined clickable-icon"
                matSuffix
                (click)="toggleShowPassword()"
                [matTooltip]="translate.instant('HidePassword')"
                *ngIf="showPassword"
            >visibility_off</mat-icon>

            <div class="error-text" *ngIf="formControl.errors?.minlength">
                {{ 'MustBeAtLeast' | translate: {Default: "Must be at least"} }} {{formElement.minLength}} {{ 'CharactersLong' | translate: {Default: "characters long"} }}
            </div>
            <div class="error-text" *ngIf="formControl.errors?.maxlength">
                {{ 'CannotBeMoreThan' | translate: {Default: "Cannot be more than"} }} {{formElement.maxLength}} {{ 'CharactersLong' | translate: {Default: "characters long"} }}
            </div>
        </mat-form-field>

        <!-- Textarea -->
        <mat-form-field *ngIf="formElement.formElementType.id === fieldTypes.TEXT_AREA" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <textarea
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="30"
            [formControlName]="formElement.formControl"></textarea>
        </mat-form-field>

        <!-- Number field -->
        <mat-form-field *ngIf="formElement.formElementType.id === fieldTypes.NUMBER_INPUT" appearance="fill">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <input matInput type="number" *ngIf="formElement.tableField?.format != 'comma-separated'" [formControlName]="formElement.formControl">
            <input matInput type="text" matInputCommified *ngIf="formElement.tableField?.format == 'comma-separated'" [formControlName]="formElement.formControl">

            <div class="error-text" *ngIf="formControl.errors?.min">
                {{ 'MustBeAtLeast' | translate: {Default: "Must be at least"} }} {{formElement.minLength}}
            </div>
            <div class="error-text" *ngIf="formControl.errors?.max">
                {{ 'CannotBeMoreThan' | translate: {Default: "Cannot be more than"} }} {{formElement.maxLength}}
            </div>
        </mat-form-field>

        <!-- Decimal Number Field -->
        <mat-form-field *ngIf="formElement.formElementType.id === fieldTypes.DECIMAL_INPUT" appearance="fill">
            <mat-label>
                {{ formElement.text }}
            </mat-label>
    <!--        <span>{{formElement.tableField}} {{formElement.tableField.format}} {{formElement.tableField.format !== formats.COMMA_SEPARATED}}</span>-->
            <input matInput type="number" placeholder="1.0" step="0.01" *ngIf="formElement.tableField?.format != formats.COMMA_SEPARATED" [formControlName]="formElement.formControl">
            <input matInput type="text" matInputCommified *ngIf="formElement.tableField?.format == formats.COMMA_SEPARATED" [formControlName]="formElement.formControl">
    <!--        <input matInput type="number" placeholder="1.0" min="0" step="0.01" *ngIf="formElement.tableField.id != 'tfi_CompensationPayRate'"  [formControlName]="formElement.formControl">-->
    <!--        <input matInput type="text" matInputCommified  *ngIf="formElement.tableField.id == 'tfi_CompensationPayRate'"  [formControlName]="formElement.formControl">-->
        </mat-form-field>

        <!-- Localization Form Array -->
        <app-localization-text-form-field
            *ngIf="formElement.formElementType.id === fieldTypes.TEXT_LOCALIZATION"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
            [cultures]="cultures"
            (emitAddtoFormArray)="emitAddtoFormArray.emit($event)"
            (emitDeleteFromFormArray)="emitDeleteFromFormArray.emit($event)"
        ></app-localization-text-form-field>

        <!-- Text Area Localization Form Array -->
        <app-localization-text-area-form-field
            *ngIf="formElement.formElementType.id === fieldTypes.TEXT_AREA_LOCALIZATION"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
            [cultures]="cultures"
            (emitAddtoFormArray)="emitAddtoFormArray.emit($event)"
            (emitDeleteFromFormArray)="emitDeleteFromFormArray.emit($event)"
        ></app-localization-text-area-form-field>

        <!-- Cost Center Form Array -->
        <div class="form-section" *ngIf="formElement.formElementType.id === fieldTypes.COST_CENTERS_SELECTION">
            <div class="form-section-title">
                <h6>{{ formElement.text }}</h6>

                <button mat-stroked-button class="add-localization-btn" setColor="primaryColour" color="primary" (click)="addNewCostCenter()" *ngIf="formElement.disabled !== 'true'">
                    <mat-icon class="material-icons-outlined">add</mat-icon> {{ 'AddCostCenter' | translate: {Default: "Add Cost Center"} }}
                </button>
            </div>
            <div class="form-section-contents">
                <div class="section-row">
                    <ng-container *ngIf="costCentreOptions | async as costCentreOptions">
                        <div class="localization-row" [formArrayName]="formElement.formControl" *ngFor="let costCenterForm of parentGroup.get(formElement.formControl).controls; let i = index">
                            <div [formGroupName]="i">
                                <!-- <div class="alert" *ngIf="checkIfSelectedCostCenterIsInactive(parentGroup.get(formElement.formControl).at(i).get('costCenter').value, costCentreOptions.data) | async">
                                    <app-bootstrap-alert
                                        [text]="translate.instant('InactiveCostCenterWarning')"
                                        type="danger">
                                    </app-bootstrap-alert>
                                </div> -->

                                <div>
                                    <mat-form-field appearance="fill">
                                        <mat-label>
                                            {{ 'CostCenter' | translate: {Default: "Cost Center"} }}

                                        </mat-label>

                                      <mat-select disableOptionCentering formControlName="costCenter">
                                        <ng-container *ngIf="costCentreOptions.data.length > 0">
                                          <mat-form-field appearance="fill" class="dropdown-search">
                                            <mat-label>
                                                {{ 'Search' | translate: {Default: "Search"} }}
                                            </mat-label>
                                            <input matInput (keyup)="onCostCenterDropdownSearchKeyReceived($event.target.value, costCentreOptions.data)">
                                          </mat-form-field>

                                          <mat-option></mat-option>
                                          <ng-container *ngIf="!costCentresFiltered; else showFiltered">
                                            <mat-option *ngFor="let costCenter of costCentreOptions.data" [value]="costCenter.id" [disabled]="isCostCenterInactive(costCenter)">{{costCenter.name}} ({{costCenter.code}})</mat-option>
                                          </ng-container>
                                          <ng-container #showFiltered>
                                            <mat-option *ngFor="let costCenter of costCentresFiltered" [value]="costCenter.id" [disabled]="isCostCenterInactive(costCenter)">{{costCenter.name}} ({{costCenter.code}})</mat-option>
                                          </ng-container>
                                        </ng-container>
                                      </mat-select>
                                    </mat-form-field>

                                    <mat-form-field appearance="fill">
                                        <mat-label>
                                            {{ 'PercentAllocated' | translate: {Default: "Percent Allocated"} }}
                                        </mat-label>

                                        <input matInput
                                                type="number"
                                                formControlName="percentAllocated"
                                                placeholder="0">
                                    </mat-form-field>

                                    <mat-icon
                                        setColor
                                        [setColorRules]="[
                                            {
                                                color: 'primaryColour',
                                                events: ['hover']
                                            }
                                        ]"
                                        class="material-icons-outlined clickable-icon"
                                        *ngIf="formElement.disabled !== 'true'"
                                        (click)="deleteLocalization(i)"
                                        matTooltip="Delete"
                                    >delete</mat-icon>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <!-- Date picker field -->
        <mat-form-field  *ngIf="formElement.formElementType.id === fieldTypes.DATE_INPUT" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <input matInput [matDatepicker]="picker" [formControlName]="formElement.formControl">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="picker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <!-- Date Time Field -->
        <mat-form-field *ngIf="formElement.formElementType.id === fieldTypes.DATE_TIME" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date" [formControlName]="formElement.formControl">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour"
                [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
                [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
                <ng-template>
                <span>OK</span>
                </ng-template>
            </ngx-mat-datetime-picker>
        </mat-form-field>

        <!-- dropdown select field -->
        <mat-form-field  *ngIf="formElement.formElementType.id === fieldTypes.DROPDOWN" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <mat-select disableOptionCentering [formControlName]="formElement.formControl">
                <ng-container *ngIf="dropdownListOptions | async as dropdownListOptions">
                    <mat-option></mat-option>
                    <ng-container *ngFor="let option of dropdownListOptions.data">
                        <mat-option *ngIf="isCurrentLookupItem(option.endDate)" [value]="option.id">{{option.text}}</mat-option>
                    </ng-container>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <!-- Probation period unit dropdown select field -->
        <mat-form-field  *ngIf="formElement.formElementType.id === fieldTypes.PROBATION_PERIOD_UNIT_DROPDOWN" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <mat-select disableOptionCentering [formControlName]="formElement.formControl">
                <ng-container *ngIf="probationPeriodUnits | async as probationPeriodUnits">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let option of probationPeriodUnits" [value]="option.id">{{option.name}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <!-- timezone dropdown select field -->
        <mat-form-field  *ngIf="formElement.formElementType.id === fieldTypes.TIMEZONE_DROPDOWN" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <mat-select disableOptionCentering [formControlName]="formElement.formControl">
                <ng-container *ngIf="timezoneOptions | async as timezoneOptions">
                    <mat-form-field appearance="fill" class="dropdown-search">
                        <mat-label>
                            {{ 'Search' | translate: {Default: "Search"} }}
                        </mat-label>
                        <input matInput (keyup)="onTimezoneDropdownSearchKeyReceived($event.target.value, timezoneOptions.data)">
                    </mat-form-field>

                    <mat-option></mat-option>

                    <ng-container *ngIf="!timezoneOptionsFiltered; else showFiltered">
                        <mat-option *ngFor="let option of timezoneOptions.data" [value]="option.id">{{option.displayName}}</mat-option>
                    </ng-container>
                    <ng-template #showFiltered>
                        <mat-option *ngFor="let option of timezoneOptionsFiltered" [value]="option.id">{{option.displayName}}</mat-option>
                    </ng-template>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <!-- culture dropdown select field -->
        <mat-form-field  *ngIf="formElement.formElementType.id === fieldTypes.CULTURE_DROPDOWN" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <mat-select disableOptionCentering [formControlName]="formElement.formControl">
                <mat-option></mat-option>
                <mat-option *ngFor="let option of cultures" [value]="option.id">{{option.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <!-- Work Location dropdown select field -->
        <mat-form-field  *ngIf="formElement.formElementType.id === fieldTypes.WORK_LOCATION_DROPDOWN" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <mat-select disableOptionCentering [formControlName]="formElement.formControl">
                <ng-container *ngIf="workLocationOptions | async as workLocationOptions">
                    <mat-form-field appearance="fill" class="dropdown-search">
                        <mat-label>
                            {{ 'Search' | translate: {Default: "Search"} }}
                        </mat-label>
                        <input matInput (keyup)="onWorkLocationDropdownSearchKeyReceived($event.target.value, workLocationOptions.data)">
                    </mat-form-field>

                    <mat-option></mat-option>

                    <ng-container *ngIf="!workLocationsFiltered; else showFiltered">
                        <mat-option *ngFor="let option of workLocationOptions.data" [value]="option.id" [disabled]="isWorkLocationInactive(option)">{{option.name}}</mat-option>
                    </ng-container>
                    <ng-template #showFiltered>
                        <mat-option *ngFor="let option of workLocationsFiltered" [value]="option.id" [disabled]="isWorkLocationInactive(option)">{{option.name}}</mat-option>
                    </ng-template>

                </ng-container>
            </mat-select>
        </mat-form-field>

        <!-- Work Rotation dropdown select field -->
        <mat-form-field  *ngIf="formElement.formElementType.id === fieldTypes.WORK_ROTATION_DROPDOWN" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <mat-select disableOptionCentering [formControlName]="formElement.formControl">
                <ng-container *ngIf="workRotationOptions | async as workRotationOptions">
                <mat-form-field appearance="fill" class="dropdown-search">
                    <mat-label>
                        {{ 'Search' | translate: {Default: "Search"} }}
                    </mat-label>
                    <input matInput (keyup)="onWorkRotationDropdownSearchKeyReceived($event.target.value, workRotationOptions.data)">
                </mat-form-field>

                <mat-option></mat-option>

                <ng-container *ngIf="!workRotationsFiltered; else showFiltered">
                    <mat-option *ngFor="let option of workRotationOptions.data" [value]="option.id">{{option.name}}</mat-option>
                </ng-container>
                <ng-template #showFiltered>
                    <mat-option *ngFor="let option of workRotationsFiltered" [value]="option.id">{{option.name}}</mat-option>
                </ng-template>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <!-- Review Plan Review Type dropdown select field -->
        <mat-form-field  *ngIf="formElement.formElementType.id === fieldTypes.REVIEW_PLAN_REVIEWER_TYPE_DROPDOWN" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <mat-select disableOptionCentering [formControlName]="formElement.formControl">
                <ng-container *ngIf="reviewerTypeOptions | async as reviewerTypeOptions">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let option of reviewerTypeOptions.data" [value]="option.id">{{option.name}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <!-- Employee form field -->
        <app-employee-form-field
            *ngIf="formElement.formElementType.id === fieldTypes.EMPLOYEE_SELECT"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        >
        </app-employee-form-field>

        <!-- Position dropdown select field -->
        <app-position-selection-form-field
            *ngIf="formElement.formElementType.id === fieldTypes.POSITION_DROPDOWN"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        >
        </app-position-selection-form-field>

        <app-review-ratings-form-field
            *ngIf="formElement.formElementType.id === fieldTypes.REVIEW_RATINGS_DROPDOWN"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-review-ratings-form-field>


        <mat-form-field  *ngIf="formElement.formElementType.id === fieldTypes.INTEGRATION_TYPE_DROPDOWN" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <mat-select disableOptionCentering [formControlName]="formElement.formControl">
                <ng-container *ngIf="integrationTypes | async as integrationTypes">
                    <mat-option></mat-option>
                    <ng-container *ngFor="let integrationType of integrationTypes.data">
                        <mat-option *ngIf="integrationType" [value]="integrationType.id">{{integrationType.displayId}}</mat-option>
                    </ng-container>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <!-- <mat-form-field  *ngIf="formElement.formElementType.id === fieldTypes.POSITION_DROPDOWN" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
                <span class="asterisk" *ngIf="formElement.requiredField === 'true'">*</span>
            </mat-label>

            <mat-select disableOptionCentering [formControlName]="formElement.formControl">
                <ng-container *ngIf="positionOptions | async as positionOptions">
                <mat-form-field appearance="fill" class="dropdown-search">
                    <mat-label>
                    Search
                    </mat-label>
                    <input matInput (keyup)="onReportsToPositionDropdownSearchKeyReceived($event.target.value, positionOptions.data)">
                </mat-form-field>

                <mat-option></mat-option>

                <ng-container *ngIf="!positionOptionsFiltered; else showFiltered">
                    <mat-option *ngFor="let option of positionOptions.data" [value]="option.id">{{option.name}}</mat-option>
                </ng-container>
                <ng-template #showFiltered>
                    <mat-option *ngFor="let option of positionOptionsFiltered" [value]="option.id">{{option.name}}</mat-option>
                </ng-template>

                </ng-container>
            </mat-select>
        </mat-form-field> -->

        <!-- currency dropdown select field -->
        <mat-form-field  *ngIf="formElement.formElementType.id === fieldTypes.CURRENCY_DROPDOWN" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <mat-select disableOptionCentering [formControlName]="formElement.formControl">
                <ng-container *ngIf="currencyOptions | async as currencyOptions">
                    <mat-option></mat-option>
                    <ng-container *ngFor="let currencyOption of currencyOptions">
                        <mat-option *ngIf="currencyOption.isEnabled" [value]="currencyOption.id">{{currencyOption.id}}</mat-option>
                    </ng-container>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <!-- Country dropdown select field -->
        <mat-form-field  *ngIf="formElement.formElementType.id === fieldTypes.COUNTRY_DROPDOWN" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <mat-select disableOptionCentering [formControlName]="formElement.formControl">
                <mat-form-field appearance="fill" class="dropdown-search">
                    <mat-label>
                        {{ 'Search' | translate: {Default: "Search"} }}
                    </mat-label>
                    <input matInput (keyup)="onCountryDropdownSearchKeyReceived($event.target.value, countryOptions)">
                </mat-form-field>

                <mat-option></mat-option>

                <ng-container *ngIf="!countryOptionsFiltered; else showFiltered">
                    <mat-option *ngFor="let option of countryOptions" [value]="option.id">{{option.name}}</mat-option>
                </ng-container>
                <ng-template #showFiltered>
                    <mat-option *ngFor="let option of countryOptionsFiltered" [value]="option.id">{{option.name}}</mat-option>
                </ng-template>
            </mat-select>
        </mat-form-field>

        <!-- Statutory Holiday Group dropdown select field -->
        <mat-form-field  *ngIf="formElement.formElementType.id === fieldTypes.STATUTORY_HOLIDAY_GROUP_DROPDOWN" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <mat-select disableOptionCentering [formControlName]="formElement.formControl">
                <ng-container *ngIf="statutoryHolidayGroups | async as statutoryHolidayGroups">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let option of statutoryHolidayGroups.data" [value]="option.id">{{option.name}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <!-- time off type dropdown select field -->
        <mat-form-field  *ngIf="formElement.formElementType.id === fieldTypes.TIME_OFF_TYPE_DROPDOWN" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <mat-select disableOptionCentering [formControlName]="formElement.formControl">
                <ng-container *ngIf="timeOffTypeList.length > 0">
                    <mat-option></mat-option>
                    <ng-container *ngFor="let timeOffTypeOption of timeOffTypeList">
                        <mat-option [value]="timeOffTypeOption.id">{{timeOffTypeOption.name}}</mat-option>
                    </ng-container>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <!-- organization type dropdown select field -->
        <mat-form-field  *ngIf="formElement.formElementType.id === fieldTypes.ORGANIZATION_TYPE" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <mat-select disableOptionCentering [formControlName]="formElement.formControl">
                <ng-container *ngIf="organizationTypes.length > 0">
                    <mat-option></mat-option>
                    <ng-container *ngFor="let organizationOption of organizationTypes">
                        <mat-option [value]="organizationOption.id">{{organizationOption.name}}</mat-option>
                    </ng-container>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <!-- pay rate interval dropdown select field -->
        <mat-form-field  *ngIf="formElement.formElementType.id === fieldTypes.PAY_RATE_INTERVAL_DROPDOWN" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <mat-select disableOptionCentering [formControlName]="formElement.formControl">
                <ng-container *ngIf="payRateIntervals.length > 0">
                    <mat-option></mat-option>
                    <ng-container *ngFor="let payRateIntervalOption of payRateIntervals">
                        <mat-option [value]="payRateIntervalOption.id">{{payRateIntervalOption.text}}</mat-option>
                    </ng-container>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <!-- goal type dropdown select field -->
        <!-- <mat-form-field  *ngIf="formElement.formElementType.id === fieldTypes.GOAL_TYPE_DROPDOWN" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <mat-select disableOptionCentering [formControlName]="formElement.formControl">
                <ng-container *ngIf="goalTypes | async as goalTypes">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let option of goalTypes.data" [value]="option.id">{{option.name}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field> -->
        <app-goal-type-selection-form-field
            *ngIf="formElement.formElementType.id === fieldTypes.GOAL_TYPE_DROPDOWN"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
            [readOnly]="readOnly"
        ></app-goal-type-selection-form-field>

        <!-- GOAL_TYPE_FIELD_SETTINGS_DROPDOWN dropdown select field -->
        <mat-form-field  *ngIf="formElement.formElementType.id === fieldTypes.GOAL_TYPE_FIELD_SETTINGS_DROPDOWN" appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>

            <mat-select disableOptionCentering [formControlName]="formElement.formControl">
                <ng-container *ngIf="fieldSettings | async as fieldSettings">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let option of fieldSettings.data" [value]="option.id">{{option.description}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <!-- Goal dropdown select field -->
        <app-goal-selection-form-field
            *ngIf="formElement.formElementType.id === fieldTypes.GOALS_DROPDOWN"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        >
        </app-goal-selection-form-field>

        <!-- Goal Plan dropdown select field -->
        <app-goal-plan-selection-form-field
            *ngIf="formElement.formElementType.id === fieldTypes.GOAL_PLANS_DROPDOWN"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        >
        </app-goal-plan-selection-form-field>

        <app-permissible-employee-goal-plans
            *ngIf="formElement.formElementType.id === fieldTypes.PERMISSIBLE_EMPLOYEE_GOAL_PLANS"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        >
        </app-permissible-employee-goal-plans>

        

        <!-- Organization dropdown tree -->
        <div class="form-section" *ngIf="formElement.formElementType.id === fieldTypes.ORGANIZATION_DROPDOWN">
            <div class="form-section-title">
                <h6>{{ formElement.text }} <span class="asterisk" *ngIf="formElement.requiredField === 'true'">*</span></h6>
            </div>
            <div class="form-section-contents">
                <div class="section-row">
                    <kendo-dropdowntree
                        class="dropdowntree"
                        kendoDropDownTreeExpandable
                        [kendoDropDownTreeFlatBinding]="parentDropdownData"
                        valueField="id"
                        textField="name"
                        [formControlName]="formElement.formControl"
                        parentIdField="parent"
                        [dataItem]="defaultDataItem"
                        [filterable]="true"
                        [expandOnFilter]="organizationFilterExpandSettings"
                    >

                        <ng-template
                                kendoDropDownTreeNodeTemplate
                                let-dataItem
                                let-index="index">
                            <div class="mat-form-field-flex">
                                {{ dataItem?.name }} ({{ dataItem?.type }})
                            </div>
                        </ng-template>

                        <ng-template kendoDropDownTreeValueTemplate let-dataItem>
                            <div class="treeview-value">
                                {{ dataItem?.name }}
                            </div>
                        </ng-template>
                    </kendo-dropdowntree>
                    <span class="asterisk" *ngIf="formElement.requiredField === 'true'">*</span>

                </div>
            </div>
        </div>

        <!-- Time Input -->
        <mat-form-field appearance="fill" *ngIf="formElement.formElementType.id === fieldTypes.TIME_INPUT">
            <mat-label>{{ formElement.text }}</mat-label>
            <input
                matInput
                [aria-label]="formElement.text"
                [ngxTimepicker]="timePicker"
                [format]="24"
                [formControlName]="formElement.formControl"
            />
            <ngx-material-timepicker
                [appendToInput]="false"
                #timePicker>
            </ngx-material-timepicker>
        </mat-form-field>

        <!-- Toggle field -->
        <div class="slide-toggle-container" *ngIf="formElement.formElementType.id === fieldTypes.TOGGLE" >
            <mat-slide-toggle setColor="primaryColour" [formControlName]="formElement.formControl" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            {{ formElement.text }} <span class="asterisk" *ngIf="formElement.requiredField === 'true'">*</span>
            </mat-slide-toggle>
        </div>

        <!-- Colour Picker Field -->
        <div class="colour-picker-container mat-form-field-wrapper" *ngIf="formElement.formElementType.id === fieldTypes.COLOR_PICKER">
            <div class="label">{{ formElement.text }} <span class="asterisk" *ngIf="formElement.requiredField === 'true'">*</span></div>
            <kendo-colorpicker
                size="large"
                format="hex"
                [formControlName]="formElement.formControl"
                [ngClass]="{'invisible':formElement.hidden === 'true'}"
            ></kendo-colorpicker>
        </div>

        <!-- <div class="slider-container" *ngIf="formElement.formElementType.id === fieldTypes.SLIDER_INPUT">
            <mat-form-field appearance="fill">
                <mat-label>{{ formElement.text }}</mat-label>
                <input #modValue type="number" [formControlName]="formElement.formControl" matInput>

                <div class="error-text" *ngIf="formControl.errors?.minlength">
                    {{ 'MustBeAtLeast' | translate: {Default: "Must be at least"} }} {{formElement.minLength}} {{ 'CharactersLong' | translate: {Default: "characters long"} }}
                </div>
                <div class="error-text" *ngIf="formControl.errors?.maxlength">
                    {{ 'CannotBeMoreThan' | translate: {Default: "Cannot be more than"} }} {{formElement.maxLength}} {{ 'CharactersLong' | translate: {Default: "characters long"} }}
                </div>
            </mat-form-field>
            <mat-slider
                [max]="formElement.maxLength"
                [min]="formElement.minLength"
                [step]="formElement.sliderStep"
                [value]="modValue.value"
                (input)="setValue($event.value)"
            >
            </mat-slider>
        </div> -->

        <div class="slider-container" *ngIf="formElement.formElementType.id === fieldTypes.SLIDER_INPUT" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <div class="slider-label">{{ formElement.text }} <span class="asterisk" *ngIf="formElement.requiredField === 'true'">*</span></div>
            <div class="slider-input">

                <mat-form-field appearance="fill" [ngClass]="{'invisible': formElement.isPercentageSlider === 'true'}">
                    <input
                        #modValue
                        type="number"
                        [max]="formElement.maxLength"
                        [min]="formElement.minLength"
                        [step]="formElement.sliderStep"
                        [formControlName]="formElement.formControl"
                        (input)="calculateSliderPercentage($event.target.value, formElement.maxLength)"
                        matInput>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <input
                        type="number"
                        [max]="formElement.maxLength * 100"
                        [min]="formElement.minLength"
                        [step]="formElement.sliderStep * 100"
                        [(value)]="percentageSliderValue"
                        [readonly]="readOnly"
                        (input)="setValue($event.target.value / 100); calculateSliderPercentage($event.target.value / 100, formElement.maxLength)"
                        matInput>
                    <mat-icon *ngIf="formElement.isPercentageSlider === 'true'" class="material-icons-outlined" matSuffix>percent</mat-icon>
                </mat-form-field>

                <div class="slider">
                    <mat-slider
                        [max]="formElement.maxLength"
                        [min]="formElement.minLength"
                        [step]="formElement.sliderStep"
                        [(value)]="modValue.value"
                        [disabled]="readOnly"
                        (input)="setValue($event.value); calculateSliderPercentage($event.value, formElement.maxLength)"
                    >
                    </mat-slider>
                </div>
            </div>
        </div>

        <app-styling-colours-form-field
            *ngIf="formElement.formElementType.id === fieldTypes.STYLING_COLOURS_DROPDOWN"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-styling-colours-form-field>

        <app-company-logo-form-field
            *ngIf="formElement.formElementType.id === fieldTypes.COMPANY_LOGO_DROPDOWN"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-company-logo-form-field>

        <app-image-upload-form-field
            *ngIf="formElement.formElementType.id === fieldTypes.IMAGE_UPLOAD"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-image-upload-form-field>

        <app-file-upload-form-field
            *ngIf="formElement.formElementType.id === fieldTypes.FILE_UPLOAD"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-file-upload-form-field>

        <app-feedback-settings-dropdown
            *ngIf="formElement.formElementType.id === fieldTypes.PERFORMANCE_FEEDBACK_SETTINGS"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-feedback-settings-dropdown>

        <app-reviewer-security-permissions-dropdown
            *ngIf="formElement.formElementType.id === 'fet_336989D'"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-reviewer-security-permissions-dropdown>

        <app-multiple-employee-select-form-field
            *ngIf="formElement.formElementType.id === fieldTypes.MULTIPLE_EMPLOYEE_SELECT"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-multiple-employee-select-form-field>

        <app-goal-type-status-form-field
            *ngIf="formElement.formElementType.id === fieldTypes.GOAL_TYPE_STATUSES"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-goal-type-status-form-field>
        
        <app-review-rating-status-form-field
            *ngIf="formElement.formElementType.id === fieldTypes.RATING_STATUS"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-review-rating-status-form-field>

        <app-goal-plan-status-form-field
            *ngIf="formElement.formElementType.id === fieldTypes.GOAL_PLAN_STATUSES_DROPDOWN"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-goal-plan-status-form-field>

        <app-kendo-editor-form-field
            *ngIf="formElement.formElementType.id === fieldTypes.HTML_EDITOR"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-kendo-editor-form-field>

        <app-letter-page-orientations-dropdown
            *ngIf="formElement.formElementType.id === fieldTypes.LETTER_PAGE_ORIENTATIONS_DROPDOWN"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-letter-page-orientations-dropdown>

        <app-letter-paper-sizes-dropdown
            *ngIf="formElement.formElementType.id === fieldTypes.LETTER_PAPER_SIZES_DROPDOWN"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-letter-paper-sizes-dropdown>

        <app-letter-recipient-types-dropdown
            *ngIf="formElement.formElementType.id === fieldTypes.LETTER_RECIPIENT_TYPES_DROPDOWN"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-letter-recipient-types-dropdown>

        <app-letter-document-types-dropdown
            *ngIf="formElement.formElementType.id === fieldTypes.LETTER_DOCUMENT_TYPES_DROPDOWN"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-letter-document-types-dropdown>

        <app-letter-header-dropdown
            *ngIf="formElement.formElementType.id === fieldTypes.LETTER_HEADER_DROPDOWN"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-letter-header-dropdown>

        <app-letter-footer-dropdown
            *ngIf="formElement.formElementType.id === fieldTypes.LETTER_FOOTER_DROPDOWN"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-letter-footer-dropdown>

        <app-letter-header-footer-status-dropdown
            *ngIf="formElement.formElementType.id === fieldTypes.LETTER_HEADERS_FOOTER_STATUSES_DROPDOWN"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-letter-header-footer-status-dropdown>

        <app-letter-template-statuses-dropdown
            *ngIf="formElement.formElementType.id === fieldTypes.LETTER_TEMPLATE_STATUSES_DROPDOWN"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-letter-template-statuses-dropdown>

        <app-multiple-organization-form-field
            *ngIf="formElement.formElementType.id === fieldTypes.MULTIPLE_ORGANIZATION_FORM_FIELD"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-multiple-organization-form-field>

        <app-letter-delivery-methods-dropdown
            *ngIf="formElement.formElementType.id === fieldTypes.LETTER_DELIVERY_METHODS_DROPDOWN"
            [ngClass]="{'invisible':formElement.hidden === 'true'}"
            [formElement]="formElement"
            [parentGroup]="parentGroup"
        ></app-letter-delivery-methods-dropdown>
    </div>
</ng-template>

