<app-site-settings-menu>
    <mat-card>
        <mat-card-title class="header">
            <h5 appLocalizationPopupDirective localizationCode="Integrations-Edit" mat-dialog-title *ngIf="integrationId">
                {{ 'Integrations-Edit' | translate: {Default: "Edit Integration"} }}
            </h5>
            <h5 appLocalizationPopupDirective localizationCode="Integrations-Create" mat-dialog-title *ngIf="!integrationId">
                {{ 'Integrations-Create' | translate: {Default: "Create New Integration"} }}
            </h5>

            <div class="buttons-container">
                <button mat-raised-button (click)="navigateBack()">{{ 'Close' | translate: {Default: "Close"} }}</button>
                <button mat-raised-button setColor="primaryColour" color="primary" (click)="getFormData = !getFormData" [disabled]="!formValid">{{ 'Save' | translate: {Default: "Save"} }}</button>
            </div>
        </mat-card-title>
        <mat-card-content>
            <ng-container *ngIf="!shouldShowForm; else loaded">
                <ngx-skeleton-loader
                    count="5"
                    [theme]="{
                      'height.px': 50
                  }"
                ></ngx-skeleton-loader>
            </ng-container>
            <ng-template #loaded>
                <app-form-generator
                    [formId]="formId"
                    [formData]="formData"
                    [emitFormDataTrigger]="getFormData"
                    (emitFormData)="formDataEmitted($event)"
                    (emitFormStatus)="formStatusUpdated($event)"
                    (emitFormPristine)="formPristineEmitted($event)"
                ></app-form-generator>

                <ng-container *ngIf="integrationSettings != null && formLoaded">
                    <app-integration-tab-container [integration]="integrationSettings.integration"></app-integration-tab-container>
                </ng-container>
            </ng-template>
        </mat-card-content>
    </mat-card>
</app-site-settings-menu>
