export enum fieldTypes {
    DROPDOWN = 'fet_3046095',
    TEXT_INPUT = 'fet_FC496D3',
    DATE_INPUT = 'fet_D262C9F',
    TOGGLE = 'fet_AE40B6C',
    BUTTON = 'fet_336989D',
    LIST_ITEM = 'fet_5CDD1AD',
    LIST_CONTAINER = 'fet_3CADE11',
    LINK = 'fet_BC64535',
    DATA_TABLE_FIELD = 'fet_FD4EC03',
    DATA_TABLE = 'fet_61F1252',
    TABLE = 'fet_F0F4BF8',
    TAB = 'fet_2144627',
    TAB_CONTAINER = 'fet_475A199',
    CONTAINER = 'fet_79AB840',
    TABLE_FIELD = 'fet_7FE6DD7',
    LABEL = 'fet_2BB27FE',
    LINE = 'fet_9FFFC5B',
    DATE_TIME = 'fet_5285C05',
    TEXT_LOCALIZATION = 'fet_TextLocalization',
    TIMEZONE_DROPDOWN = 'fet_TimezoneDropdown',
    CULTURE_DROPDOWN = 'fet_CultureDropDown',
    NUMBER_INPUT = 'fet_NumberInput',
    DECIMAL_INPUT = 'fet_DecimalInput',
    TEXT_AREA = 'fet_TextArea',
    CURRENCY_DROPDOWN = 'fet_CurrencyDropdown',
    COST_CENTERS_SELECTION = 'fet_CostCenterSelection',
    POSITION_DROPDOWN = 'fet_PositionsDropDown',
    WORK_LOCATION_DROPDOWN = 'fet_WorkLocationDropDown',
    WORK_ROTATION_DROPDOWN = 'fet_WorkRotationDropDown',
    ORGANIZATION_DROPDOWN = 'fet_OrganizationDropdown',
    MULTIPLE_ORGANIZATION_FORM_FIELD = 'fet_MultipleOrganizationFormField',
    TIME_INPUT = 'fet_TimeInput',
    TIME_OFF_TYPE_DROPDOWN = 'fet_TimeoffTypeDropdown',
    ORGANIZATION_TYPE = 'fet_OrganizationType',
    PAY_RATE_INTERVAL_DROPDOWN = 'fet_PayRateIntervalDropDown',
    COUNTRY_DROPDOWN = 'fet_CountryDropDown',
    STATUTORY_HOLIDAY_GROUP_DROPDOWN = 'fet_StatutoryHolidayGroupDropdown',
    PROBATION_PERIOD_UNIT_DROPDOWN = 'fet_ProbationPeriodUnitDropDown',
    PASSWORD_INPUT = 'fet_Password',
    GOAL_TYPE_DROPDOWN = 'fet_GoalTypeDropdown',
    TEXT_AREA_LOCALIZATION = 'fet_TextAreaLocalization',
    SLIDER_INPUT = 'fet_SliderInput',
    EXPECTED_COMPLETION_DATE_TYPE_DROPDOWN = 'fet_ExpectedCompletionDateTypeDropDown',
    COMPLETION_DATE_TYPE_DROPDOWN = 'fet_CompletionDateTypeDropDown',
    GOAL_TYPE_FIELD_SETTINGS_DROPDOWN = 'fet_GoalTypeFieldSettingsDropDown',
    GOALS_DROPDOWN = 'fet_GoalsDropdown',
    GOAL_PLANS_DROPDOWN = 'fet_GoalPlansDropdown',
    REVIEW_PLAN_REVIEWER_TYPE_DROPDOWN = 'fet_ReviewPlanReviewerTypeDropdown',
    EMPLOYEE_SELECT = 'fet_EmployeeSelect',
    MULTIPLE_EMPLOYEE_SELECT = 'fet_MultipleEmployeeSelect',
    REVIEW_RATINGS_DROPDOWN = 'fet_ReviewRatingsDropdown',
    COLOR_PICKER = 'fet_ColorPicker',
    STYLING_COLOURS_DROPDOWN = 'fet_StylingColoursDropDown',
    IMAGE_UPLOAD = 'fet_ImageUpload',
    FILE_UPLOAD = 'fet_FileUpload',
    COMPANY_LOGO_DROPDOWN = 'fet_CompanyLogoDropdown',
    PERMISSIBLE_EMPLOYEE_GOAL_PLANS = 'fet_PermissibleEmployeeGoalPlans',
    PERFORMANCE_FEEDBACK_SETTINGS = 'fet_PerformanceFeedbackSettings',
    GOAL_TYPE_STATUSES = 'fet_GoalTypeStatuses',
    RATING_STATUS = 'fet_RatingStatus',
    GOAL_PLAN_STATUSES_DROPDOWN = 'fet_GoalPlanStatusesDropDown',
    HTML_EDITOR = 'fet_HtmlEditor',
    LETTER_PAGE_ORIENTATIONS_DROPDOWN = 'fet_LetterPageOrientationsDropdown',
    LETTER_PAPER_SIZES_DROPDOWN = 'fet_LetterPaperSizesDropdown',
    LETTER_RECIPIENT_TYPES_DROPDOWN = 'fet_LetterRecipientTypesDropdown',
    LETTER_DOCUMENT_TYPES_DROPDOWN = 'fet_LetterDocumentTypesDropdown',
    LETTER_HEADER_DROPDOWN = 'fet_LetterHeaderDropdown',
    LETTER_FOOTER_DROPDOWN = 'fet_LetterFooterDropdown',
    LETTER_TEMPLATE_DROPDOWN = 'fet_LetterTemplateDropdown',
    LETTER_TEMPLATE_STATUSES_DROPDOWN = 'fet_LetterTemplateStatusesDropdown',
    LETTER_HEADERS_FOOTER_STATUSES_DROPDOWN = 'fet_HeaderFooterStatusDropDown',
    LETTER_DELIVERY_METHODS_DROPDOWN = 'fet_LetterDeliveryMethodsDropdown',
    INTEGRATION_TYPE_DROPDOWN = 'fet_IntegrationTypeDropdown',
}
