import { Injectable } from '@angular/core';
import { Environment } from "@app/shared/models/environment.model";


@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {
    today = new Date();
    public env: Environment = this.setEnvironment(window.location.href);
    public isDebug = false

    constructor() {
    }

    private setEnvironment(url: string): Environment {

        if (url.includes('aurora-dev.hubbubhr.com')) {
            return this.devEnvironment();

        }
        else if (url.includes('development2.hubbubhr.com')) {
            return this.dev2Environment();

        } else if (url.includes('development3.hubbubhr.com')) {
            return this.dev3Environment();

        } else if (url.includes('qa.hubbubhr.com')) {
            return this.qaEnvironment();

        } else if (url.includes('template.hubbubhr.com')) {
            return this.templateEnvironment();

        } else if (url.includes('production-ca.hubbubhr.com')) {
            return this.prodCanadaEnvironment();

        } else if (url.includes('production-eu.hubbubhr.com')) {
            return this.prodEuEnvironment();

        } else if (url.includes('staging.hubbubhr.com')) {
            return this.stagingEnvironment();

        } else if (url.includes('staging-eu-qa.hubbubhr.com')) {
            return this.stagingEuQaEnvironment();

        } else if (url.includes('sightsavers-sandbox.hubbubhr.com')) {
            return this.stagingSightSaversEnvironment();

        } else if (url.includes('lithium-americas.hubbubhr.com')) {
            return this.prodLithiumAmericasEnvironment();

        }
        else if (url.includes('lithium-americas-sandbox.hubbubhr.com')) {
            return this.stagingLithiumAmericasEnvironment();

        } else if (url.includes('sightsavers.hubbubhr.com')) {
            return this.prodEuSightSaversEnvironment();

        } else if (url.includes('artemis.hubbubhr.com')) {
            return this.prodArtemisEnvironment();

        } else if (url.includes('artemis-sandbox.hubbubhr.com')) {
            return this.stagingArtemisEnvironment();

        } else if (url.includes('coppermtn.hubbubhr.com')) {
            return this.prodCopperMountainEnvironment();

        }
        else if (url.includes('thelinkmb.hubbubhr.com')) {
            return this.prodTheLinkMbEnvironment();

        }
        else if (url.includes('thelinkmb-sandbox.hubbubhr.com')) {
            return this.stagingTheLinkMbEnvironment();

        }
        else if (url.includes('northerngraphite.hubbubhr.com')) {
            return this.prodNorthernGraphiteEnvironment();

        }
        else if (url.includes('northerngraphite-sandbox.hubbubhr.com')) {
            return this.stagingNorthernGraphiteEnvironment();

        }
        else if (url.includes('b2gold-sandbox.hubbubhr.com')) {
            return this.b2GoldSandboxEnvironment();

        }
        else {
            this.isDebug = true;
            // return this.localEnvironmentPointingAtDev2(false);
            return this.localEnvironmentPointingAtDev(false);
        }
    }

    private localEnvironmentPointingAtDev2(localSever: boolean = false): Environment {

        const config = {
            baseUrl: 'https://localhost:4200/',
            apiUrl: 'https://api-dev.hubbubhr.com/',
            authUrl: 'https://dev-auth.hubbubhr.com/',
            tenantId: '3BF0F154-E714-48F8-BB34-486FE1EB167C',
            clientId: 'silent-refresh',
        }

        if (localSever) {
            config.apiUrl = 'https://localhost:5001/';
        }

        if (this.today.getMonth() === 11) {
            const christmasConfig = {
                customLogoPath: 'assets/HubbubHrLogoChristmas.png',
                customCss: 'client-sight-savers.css',
                customTheme: 'christmasTheme'
            }

            return {...config, ...christmasConfig};
        } else {
            return config;
        }
    }

    private localEnvironmentPointingAtDev(localSever: boolean = false): Environment {

        const config = {
            baseUrl: 'https://localhost:4200/',
            apiUrl: 'https://api-dev.hubbubhr.com/',
            authUrl: 'https://dev-auth.hubbubhr.com/',
            tenantId: '3BF0F154-E714-48F8-BB34-486FE1EB167C',
            clientId: 'silent-refresh',
        }

        if (localSever) {
            config.apiUrl = 'https://localhost:5001/';
        }

        if (this.today.getMonth() === 11) {
            const christmasConfig = {
                customLogoPath: 'assets/HubbubHrLogoChristmas.png',
                customCss: 'client-sight-savers.css',
                customTheme: 'christmasTheme'
            }

            return {...config, ...christmasConfig};
        } else {
            return config;
        }
    }

    private devEnvironment(): Environment {
        const config = {
            baseUrl: 'https://aurora-dev.hubbubhr.com/',
            apiUrl: 'https://api-dev.hubbubhr.com/',
            authUrl: 'https://dev-auth.hubbubhr.com/',
            tenantId: '3BF0F154-E714-48F8-BB34-486FE1EB167C',
            clientId: 'silent-refresh',
        }

        if (this.today.getMonth() === 11) {
            const christmasConfig = {
                customLogoPath: 'assets/HubbubHrLogoChristmas.png',
                customCss: 'client-sight-savers.css',
                customTheme: 'christmasTheme'
            }

            return {...config, ...christmasConfig};
        } else {
            return config;
        }
    }

    private dev2Environment(): Environment {
        return {
            baseUrl: 'https://development2.hubbubhr.com/',
            apiUrl: 'https://dev2-api.hubbubhr.com/',
            authUrl: 'https://dev2-auth.hubbubhr.com/',
            tenantId: '3BF0F154-E714-48F8-BB34-486FE1EB167C',
            clientId: 'silent-refresh'
        }
    }

    private dev3Environment(): Environment {
        return {
            baseUrl: 'https://development3.hubbubhr.com/',
            apiUrl: 'https://dev3-api.hubbubhr.com/',
            authUrl: 'https://dev3-auth.hubbubhr.com/',
            tenantId: '3BF0F154-E714-48F8-BB34-486FE1EB167C',
            clientId: 'silent-refresh'
        }
    }

    private qaEnvironment(): Environment {
        if (this.today.getMonth() === 11) {
            return {
                baseUrl: 'https://qa.hubbubhr.com/',
                apiUrl: 'https://qa-api.hubbubhr.com/',
                authUrl: 'https://qa-auth.hubbubhr.com/',
                tenantId: 'D0FC5222-AFCC-4D8D-BBEB-14276355AA98',
                clientId: 'hubbubhr',
                customLogoPath: 'assets/HubbubHrLogoChristmas.png',
                customCss: 'client-sight-savers.css',
                customTheme: 'christmasTheme'
            }
        }
        else {
            return {
                baseUrl: 'https://qa.hubbubhr.com/',
                apiUrl: 'https://qa-api.hubbubhr.com/',
                authUrl: 'https://qa-auth.hubbubhr.com/',
                tenantId: 'D0FC5222-AFCC-4D8D-BBEB-14276355AA98',
                clientId: 'hubbubhr',
            }
        }
    }

    private stagingEuQaEnvironment(): Environment {
        return {
            baseUrl: 'https://staging-eu-qa.hubbubhr.com/',
            apiUrl: 'https://eu-api-staging.hubbubhr.com/',
            authUrl: 'https://auth-staging.hubbubhr.com/',
            tenantId: 'D0FC5222-AFCC-4D8D-BBEB-14276355AA98',
            clientId: 'hubbubhr',
            // customLogoPath: 'assets/SS-Logo.png',
            // customCss: 'client-sight-savers.css',
            // customTheme: 'sightSavers'
        }
    }

    private stagingEnvironment(): Environment {
        return {
            baseUrl: 'https://staging.hubbubhr.com/',
            apiUrl: 'https://staging-api.hubbubhr.com/',
            authUrl: 'https://staging-auth.hubbubhr.com/',
            tenantId: 'D0FC5222-AFCC-4D8D-BBEB-14276355AA98',
            clientId: 'hubbubhr',
            // customLogoPath: 'assets/SS-Logo.png',
            // customCss: 'client-sight-savers.css',
            // customTheme: 'sightSavers'
        }
    }

    private templateEnvironment(): Environment {
        return {
            baseUrl: 'https://template.hubbubhr.com/',
            apiUrl: 'https://api.hubbubhr.com/',
            authUrl: 'https://auth.hubbubhr.com/',
            tenantId: 'C679B5B1-F45D-403C-A8B3-E5FA325E8B09',
            clientId: 'hubbubhr'
        }
    }

    // private prodEnvironment(): Environment {
    //     return {
    //         baseUrl: 'https://production-ca.hubbubhr.com/',
    //         apiUrl: 'https://api-dev.hubbubhr.com/',
    //         authUrl: 'https://auroraoauth.azurewebsites.net/',
    //         tenantId: '3BF0F154-E714-48F8-BB34-486FE1EB167C',
    //         clientId: 'angular-wtf',
    //     }
    // }

    private prodCanadaEnvironment(): Environment {
        return {
            baseUrl: 'https://production-ca.hubbubhr.com/',
            apiUrl: 'https://api.hubbubhr.com/',
            authUrl: 'https://auth.hubbubhr.com/',
            tenantId: '90B44DAB-44C8-4EEE-A827-3F46A29ADD16',
            clientId: 'hubbubhr',
        }
    }

    private prodEuEnvironment(): Environment {
        return {
            baseUrl: 'https://production-eu.hubbubhr.com/',
            apiUrl: 'https://api.hubbubhr.com/',
            authUrl: 'https://auth.hubbubhr.com/',
            tenantId: '0F223404-1F03-410E-B7A0-B984F84EC4BD',
            clientId: 'hubbubhr',
        }
    }

    private prodEuSightSaversEnvironment(): Environment {
        return {
            baseUrl: 'https://sightsavers.hubbubhr.com/',
            apiUrl: 'https://eu-api.hubbubhr.com/',
            authUrl: 'https://auth.hubbubhr.com/',
            tenantId: '30831229-CAA4-4586-A4B7-F332416599C9',
            clientId: 'hubbubhr',
            customLogoPath: 'assets/SS-Logo.png',
            customCss: 'client-sight-savers.css',
            customTheme: 'sightSavers'
        }
    }

    private stagingSightSaversEnvironment(): Environment {
        return {
            baseUrl: 'https://sightsavers-sandbox.hubbubhr.com/',
            apiUrl: 'https://eu-sandbox-api.hubbubhr.com/',
            authUrl: 'https://sandbox-auth.hubbubhr.com/',
            tenantId: '30831229-CAA4-4586-A4B7-F332416599C9',
            clientId: 'hubbubhr',
            customLogoPath: 'assets/SS-Logo.png',
            // customCss: 'client-sight-savers.css',
            // customTheme: 'sightSavers'
        }
    }

    private prodCopperMountainEnvironment(): Environment {
        return {
            baseUrl: 'https://coppermtn.hubbubhr.com/',
            apiUrl: 'https://api.hubbubhr.com/',
            authUrl: 'https://auth.hubbubhr.com/',
            tenantId: 'E378DFDB-FC34-49FF-9E3C-097A6AEF640E',
            clientId: 'hubbubhr'
        }
    }

    private prodLithiumAmericasEnvironment(): Environment {
        return {
            baseUrl: 'https://lithium-americas.hubbubhr.com/',
            apiUrl: 'https://api.hubbubhr.com/',
            authUrl: 'https://auth.hubbubhr.com/',
            tenantId: '03E95439-EBCF-4C63-8E5B-CECD02E3FE80',
            clientId: 'hubbubhr',
        }
    }

    private stagingLithiumAmericasEnvironment(): Environment {
        return {
            baseUrl: 'https://lithium-americas-sandbox.hubbubhr.com/',
            apiUrl: 'https://sandbox-api.hubbubhr.com/',
            authUrl: 'https://sandbox-auth.hubbubhr.com/',
            tenantId: '03E95439-EBCF-4C63-8E5B-CECD02E3FE80',
            clientId: 'hubbubhr',
        }
    }

    private prodArtemisEnvironment(): Environment {
        return {
            baseUrl: 'https://artemis.hubbubhr.com/',
            apiUrl: 'https://api.hubbubhr.com/',
            authUrl: 'https://auth.hubbubhr.com/',
            tenantId: 'C31A1F52-69EA-4924-94DA-779CCA5D24B9',
            clientId: 'hubbubhr',
        }
    }

    private stagingArtemisEnvironment(): Environment {
        return {
            baseUrl: 'https://artemis-sandbox.hubbubhr.com/',
            apiUrl: 'https://sandbox-api.hubbubhr.com/',
            authUrl: 'https://sandbox-auth.hubbubhr.com/',
            tenantId: 'C31A1F52-69EA-4924-94DA-779CCA5D24B9',
            clientId: 'hubbubhr',
        }
    }

    private prodTheLinkMbEnvironment(): Environment {
        return {
            baseUrl: 'https://thelinkmb.hubbubhr.com/',
            apiUrl: 'https://api.hubbubhr.com/',
            authUrl: 'https://auth.hubbubhr.com/',
            tenantId: '1E7032D5-F848-44DD-B10D-1F090739F9EE',
            clientId: 'hubbubhr',
            customLogoPath: 'assets/link-mb-logo.jpg',
            customCss: 'client-b2gold.scss',
            customTheme: 'theLinkMbTheme'
        }
    }

    private stagingTheLinkMbEnvironment(): Environment {
        return {
            baseUrl: 'https://thelinkmb-sandbox.hubbubhr.com/',
            apiUrl: 'https://sandbox-api.hubbubhr.com/',
            authUrl: 'https://sandbox-auth.hubbubhr.com/',
            tenantId: '1E7032D5-F848-44DD-B10D-1F090739F9EE',
            clientId: 'hubbubhr',
        }
    }

    private stagingNorthernGraphiteEnvironment(): Environment {
        return {
            baseUrl: 'https://northerngraphite-sandbox.hubbubhr.com/',
            apiUrl: 'https://sandbox-api.hubbubhr.com/',
            authUrl: 'https://sandbox-auth.hubbubhr.com/',
            tenantId: '57F14B16-4190-46A7-BB45-EC220845FEF5',
            clientId: 'hubbubhr',
        }
    }

    private prodNorthernGraphiteEnvironment(): Environment {
        return {
            baseUrl: 'https://northerngraphite.hubbubhr.com/',
            apiUrl: 'https://api.hubbubhr.com/',
            authUrl: 'https://auth.hubbubhr.com/',
            tenantId: '57F14B16-4190-46A7-BB45-EC220845FEF5',
            clientId: 'hubbubhr',
            customLogoPath: 'assets/northern-graphite-logo.png',
            customCss: 'client-northern-graphite.css',
            customTheme: 'northernGraphite'
        }
    }


    private b2GoldSandboxEnvironment(): Environment {
        return {
            baseUrl: 'https://b2gold-sandbox.hubbubhr.com/',
            apiUrl: 'https://sandbox-api.hubbubhr.com/',
            authUrl: 'https://sandbox-auth.hubbubhr.com/',
            tenantId: '37E9A442-B359-4965-ADF5-1D7CB43C3135',
            clientId: 'hubbubhr',
            customCss: 'client-b2gold.scss',
            customTheme: 'b2gold',
            customLogoPath: 'assets/B2Gold-logo.jpg',
        }
    }

    // SAMPLE ENVIRONMENT
    // private prodSampleEnvironment(): Environment {
    //     return {
    //         baseUrl: '',
    //         apiUrl: '',
    //         authUrl: '',
    //         tenantId: '',
    //         clientId: '',
    //     }
    // }

}
