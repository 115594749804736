<!-- Text field -->
<mat-form-field *ngIf="field.formElementType.id === fieldTypes.TEXT_INPUT || field.formElementType.id === fieldTypes.PASSWORD_INPUT" appearance="fill">
    <mat-label>
        {{ field.text }}
        <span class="asterisk" *ngIf = "field.requiredField === 'true'">*</span>
    </mat-label>

    <span class="material-icons-outlined"  *ngIf="field.hidden === 'true'" matSuffix>
        disabled_visible
    </span>

    <input matInput type="text" [formControl]="formControl">
</mat-form-field>

<!-- Text Area -->
<mat-form-field *ngIf="field.formElementType.id === fieldTypes.TEXT_AREA" appearance="fill">
    <mat-label>
        {{ field.text }}
        <span class="asterisk" *ngIf = "field.requiredField === 'true'">*</span>
    </mat-label>

    <textarea matInput [formControl]="formControl"></textarea>
</mat-form-field>

<!-- Number field -->
<mat-form-field *ngIf="field.formElementType.id === fieldTypes.NUMBER_INPUT" appearance="fill">
    <mat-label>
        {{ field.text }}
        <span class="asterisk" *ngIf = "field.requiredField === 'true'">*</span>
    </mat-label>

    <span class="material-icons-outlined"  *ngIf="field.hidden === 'true'" matSuffix>
        disabled_visible
    </span>

    <input matInput type="number" [formControl]="formControl">
</mat-form-field>

<!-- Decimal Number Field -->
<mat-form-field *ngIf="field.formElementType.id === fieldTypes.DECIMAL_INPUT" appearance="fill">
    <mat-label>
        {{ field.text }}
        <span class="asterisk" *ngIf = "field.requiredField === 'true'">*</span>
    </mat-label>

    <span class="material-icons-outlined"  *ngIf="field.hidden === 'true'" matSuffix>
        disabled_visible
    </span>

    <input matInput type="number" placeholder="1.0" min="0" step="0.01" [formControl]="formControl">

</mat-form-field>

<!-- Localization Form Array -->
<div class="form-section" *ngIf="field.formElementType.id === fieldTypes.TEXT_LOCALIZATION || field.formElementType.id === fieldTypes.TEXT_AREA_LOCALIZATION">
    <div class="form-section-title">
        <h6>
            {{ field.text }}

            <span class="material-icons-outlined"  *ngIf="field.hidden === 'true'" matSuffix>
                disabled_visible
            </span>
        </h6>
    </div>
    <div class="form-section-contents">
        <div class="section-row">
            <div class="form-row">
                <div class="localization-row">
                    <mat-form-field appearance="fill">
                        <mat-label>
                            Select Language
                        </mat-label>

                        <mat-select disableOptionCentering >
                            <mat-option></mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            {{ field.text }}
                        </mat-label>

                        <input matInput>
                    </mat-form-field>

                    <mat-icon
                        class="material-icons-outlined clickable-icon"
                        matTooltip="Delete"
                        setColor
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                events: ['hover']
                            }
                        ]"
                    >delete</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Cost Center Form Array -->
<div class="form-section" *ngIf="field.formElementType.id === fieldTypes.COST_CENTERS_SELECTION">
    <div class="form-section-title">
        <h6>
            {{ field.text }}

            <span class="material-icons-outlined"  *ngIf="field.hidden === 'true'" matSuffix>
                disabled_visible
            </span>
        </h6>

    </div>
    <div class="form-section-contents">
        <div class="section-row">
            <div class="form-row">
                <div class="localization-row">
                    <mat-form-field appearance="fill">
                        <mat-label>
                            {{ 'CostCenter' | translate: {Default: "Cost Center"} }}
                        </mat-label>

                        <mat-select disableOptionCentering >
                            <mat-option></mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            {{ 'PercentAllocated' | translate: {Default: "Percent Allocated"} }}
                        </mat-label>

                        <input matInput>
                    </mat-form-field>

                    <mat-icon
                        setColor
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                events: ['hover']
                            }
                        ]"
                        class="material-icons-outlined clickable-icon"
                        matTooltip="Delete"
                    >delete</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Organization Dropdown tree -->
<div class="form-section" *ngIf="field.formElementType.id === fieldTypes.ORGANIZATION_DROPDOWN">
    <div class="form-section-title">
        <h6>
            {{ field.text }}

            <span class="material-icons-outlined"  *ngIf="field.hidden === 'true'" matSuffix>
                disabled_visible
            </span>
        </h6>

    </div>
    <div class="form-section-contents">
        <div class="section-row">
            <div class="form-row">
                <mat-form-field appearance="fill">
                    <mat-label>
                        <span class="asterisk" *ngIf="field.requiredField === 'true'">*</span>
                    </mat-label>

                    <mat-select disableOptionCentering>
                        <mat-option></mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>

<!-- Date picker field -->
<mat-form-field  *ngIf="field.formElementType.id === fieldTypes.DATE_INPUT" appearance="fill">
    <mat-label>
        {{ field.text }}
        <span class="asterisk" *ngIf = "field.requiredField === 'true'">*</span>
    </mat-label>

    <input matInput [matDatepicker]="picker" [formControl]="formControl">
    <span fxLayout="row" matSuffix>
        <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>

        <span class="material-icons-outlined"  *ngIf="field.hidden === 'true'" matSuffix>
            disabled_visible
        </span>
    </span>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

<!-- Date Time Field -->
<mat-form-field *ngIf="field.formElementType.id === fieldTypes.DATE_TIME" appearance="fill">
    <mat-label>
        {{ field.text }}
        <span class="asterisk" *ngIf = "field.requiredField === 'true'">*</span>
    </mat-label>

    <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date" [formControl]="formControl">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour"
        [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
        [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
        <!-- Custom icon or text of Apply icon -->
        <ng-template>
        <span>OK</span>
        </ng-template>
    </ngx-mat-datetime-picker>
</mat-form-field>

<!-- dropdown select field -->
<mat-form-field
    *ngIf="field.formElementType.id === fieldTypes.DROPDOWN ||
    field.formElementType.id === fieldTypes.CULTURE_DROPDOWN ||
    field.formElementType.id === fieldTypes.TIMEZONE_DROPDOWN ||
    field.formElementType.id === fieldTypes.WORK_LOCATION_DROPDOWN ||
    field.formElementType.id === fieldTypes.WORK_ROTATION_DROPDOWN ||
    field.formElementType.id === fieldTypes.POSITION_DROPDOWN ||
    field.formElementType.id === fieldTypes.CURRENCY_DROPDOWN ||
    field.formElementType.id === fieldTypes.TIME_OFF_TYPE_DROPDOWN ||
    field.formElementType.id === fieldTypes.TIME_INPUT ||
    field.formElementType.id === fieldTypes.PAY_RATE_INTERVAL_DROPDOWN ||
    field.formElementType.id === fieldTypes.COUNTRY_DROPDOWN ||
    field.formElementType.id === fieldTypes.STATUTORY_HOLIDAY_GROUP_DROPDOWN ||
    field.formElementType.id === fieldTypes.PROBATION_PERIOD_UNIT_DROPDOWN ||
    field.formElementType.id === fieldTypes.GOAL_TYPE_DROPDOWN ||
    field.formElementType.id === fieldTypes.ORGANIZATION_TYPE ||
    field.formElementType.id === fieldTypes.EXPECTED_COMPLETION_DATE_TYPE_DROPDOWN ||
    field.formElementType.id === fieldTypes.COMPLETION_DATE_TYPE_DROPDOWN  ||
    field.formElementType.id === fieldTypes.GOAL_TYPE_FIELD_SETTINGS_DROPDOWN ||
    field.formElementType.id === fieldTypes.GOALS_DROPDOWN ||
    field.formElementType.id === fieldTypes.GOAL_PLANS_DROPDOWN ||
    field.formElementType.id === fieldTypes.REVIEW_PLAN_REVIEWER_TYPE_DROPDOWN ||
    field.formElementType.id === fieldTypes.EMPLOYEE_SELECT ||
    field.formElementType.id === fieldTypes.MULTIPLE_EMPLOYEE_SELECT ||
    field.formElementType.id === fieldTypes.STYLING_COLOURS_DROPDOWN ||
    field.formElementType.id === fieldTypes.REVIEW_RATINGS_DROPDOWN ||
    field.formElementType.id === fieldTypes.IMAGE_UPLOAD ||
    field.formElementType.id === fieldTypes.FILE_UPLOAD ||
    field.formElementType.id === fieldTypes.COMPANY_LOGO_DROPDOWN ||
    field.formElementType.id === fieldTypes.PERMISSIBLE_EMPLOYEE_GOAL_PLANS ||
    field.formElementType.id === fieldTypes.PERFORMANCE_FEEDBACK_SETTINGS || 
    field.formElementType.id === fieldTypes.GOAL_TYPE_STATUSES || 
    field.formElementType.id === fieldTypes.RATING_STATUS || 
    field.formElementType.id === fieldTypes.GOAL_PLAN_STATUSES_DROPDOWN || 
    field.formElementType.id === 'fet_336989D' ||
    field.formElementType.id === fieldTypes.LETTER_PAGE_ORIENTATIONS_DROPDOWN || 
    field.formElementType.id === fieldTypes.LETTER_PAPER_SIZES_DROPDOWN ||
    field.formElementType.id === fieldTypes.LETTER_RECIPIENT_TYPES_DROPDOWN ||
    field.formElementType.id === fieldTypes.LETTER_DOCUMENT_TYPES_DROPDOWN ||
    field.formElementType.id === fieldTypes.LETTER_FOOTER_DROPDOWN ||
    field.formElementType.id === fieldTypes.LETTER_HEADER_DROPDOWN ||
    field.formElementType.id === fieldTypes.LETTER_TEMPLATE_DROPDOWN ||
    field.formElementType.id === fieldTypes.LETTER_TEMPLATE_STATUSES_DROPDOWN ||
    field.formElementType.id === fieldTypes.MULTIPLE_ORGANIZATION_FORM_FIELD ||
    field.formElementType.id === fieldTypes.INTEGRATION_TYPE_DROPDOWN || 
    field.formElementType.id === fieldTypes.LETTER_HEADERS_FOOTER_STATUSES_DROPDOWN ||
    field.formElementType.id === fieldTypes.LETTER_DELIVERY_METHODS_DROPDOWN
    "

    appearance="fill">
    <mat-label>
        {{ field.text }}
        <span class="asterisk" *ngIf="field.requiredField === 'true'">*</span>
    </mat-label>

    <span class="material-icons-outlined"  *ngIf="field.hidden === 'true'" matSuffix>
        disabled_visible
    </span>

    <mat-select disableOptionCentering>
        <mat-option></mat-option>
    </mat-select>
</mat-form-field>

<!-- Toggle -->
<div class="slide-toggle-container">
    <mat-slide-toggle setColor="primaryColour" *ngIf="field.formElementType.id === fieldTypes.TOGGLE">
        {{ field.text }}

        <span class="material-icons-outlined"  *ngIf="field.hidden === 'true'" matSuffix>
            disabled_visible
        </span>
    </mat-slide-toggle>
</div>

<!-- Slider -->
<div class="slide-toggle-container" *ngIf="field.formElementType.id === fieldTypes.SLIDER_INPUT">
    {{ field.text }}
    <mat-slider>
        <input matSliderThumb>
    </mat-slider>
</div>

<!-- Colour Picker -->
<div class="colour-picker-container" *ngIf="field.formElementType.id === fieldTypes.COLOR_PICKER">
    <mat-label>
        {{ field.text }}
        <span class="asterisk" *ngIf="field.requiredField === 'true'">*</span>
    </mat-label>

    <kendo-colorpicker 
        size="large"
        format="hex"
    ></kendo-colorpicker>
    <span class="material-icons-outlined"  *ngIf="field.hidden === 'true'" matSuffix>
        disabled_visible
    </span>
</div>

<!-- HTML Editor -->
<div class="form-section" *ngIf="field.formElementType.id === fieldTypes.HTML_EDITOR">
    <div class="form-section-title">
        <h6>
            {{ field.text }}
        
            <span class="material-icons-outlined"  *ngIf="field.hidden === 'true'" matSuffix>
                disabled_visible
            </span>
        </h6>
    </div>
    <div class="form-section-contents">
        <div class="section-row">
            <kendo-editor style="height: 300px; width: 690px;"></kendo-editor>
        </div>
    </div>
</div>
