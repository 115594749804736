import {Localization} from '@app/shared/models/localization.model';
import {Version} from '@app/shared/models/version.model';

/*
.##.......########.########.########.########.########..##.....##.########....###....########.
.##.......##..........##.......##....##.......##.....##.##.....##.##.........##.##...##.....##
.##.......##..........##.......##....##.......##.....##.##.....##.##........##...##..##.....##
.##.......######......##.......##....######...########..#########.######...##.....##.##.....##
.##.......##..........##.......##....##.......##...##...##.....##.##.......#########.##.....##
.##.......##..........##.......##....##.......##....##..##.....##.##.......##.....##.##.....##
.########.########....##.......##....########.##.....##.##.....##.########.##.....##.########.
*/

export class Letterhead {
    id: string
    name: string
    description: string
    status: { id: string, name: string }
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
    createdOn: string
    version: Version
}

export class LetterheadVerbose {
    id: string
    name: Localization[]
    description: Localization[]
    status: { id: string, name: string }
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
    createdOn: string
    height: number
    html: string
    version: Version
}

export class LetterheadSubmit {
    name: Localization[]
    description: Localization[]
    status: string
    height: number
    html: string
    changeReason: string
    changeReasonComments: string
}

export class LetterheadHistory {
    id: string
    name: string
    status: { id: string, name: string }
    description: string
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
    createdOn: string
    version: Version
}

/*
.##.......########.########.########.########.########.....########..#######...#######..########.########.########.
.##.......##..........##.......##....##.......##.....##....##.......##.....##.##.....##....##....##.......##.....##
.##.......##..........##.......##....##.......##.....##....##.......##.....##.##.....##....##....##.......##.....##
.##.......######......##.......##....######...########.....######...##.....##.##.....##....##....######...########.
.##.......##..........##.......##....##.......##...##......##.......##.....##.##.....##....##....##.......##...##..
.##.......##..........##.......##....##.......##....##.....##.......##.....##.##.....##....##....##.......##....##.
.########.########....##.......##....########.##.....##....##........#######...#######.....##....########.##.....##
*/

export class LetterFooter {
    id: string
    name: string
    description: string
    status: { id: string, name: string }
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
    createdOn: string
    version: Version
}

export class LetterFooterVerbose {
    id: string
    name: Localization[]
    description: Localization[]
    status: { id: string, name: string }
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
    createdOn: string
    height: number
    html: string
    version: Version
}

export class LetterFooterSubmit {
    name: Localization[]
    description: Localization[]
    height: number
    status: string
    html: string
    changeReason: string
    changeReasonComments: string
}

export class LetterFooterHistory {
    id: string
    name: string
    description: string
    status: { id: string, name: string }
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
    createdOn: string
    version: Version
}

/*
.########.########.##.....##.########..##..........###....########.########..######.
....##....##.......###...###.##.....##.##.........##.##......##....##.......##....##
....##....##.......####.####.##.....##.##........##...##.....##....##.......##......
....##....######...##.###.##.########..##.......##.....##....##....######....######.
....##....##.......##.....##.##........##.......#########....##....##.............##
....##....##.......##.....##.##........##.......##.....##....##....##.......##....##
....##....########.##.....##.##........########.##.....##....##....########..######.
*/

export class LetterTemplate {
    id: string
    name: string
    description: string
    status: {
        id: string
        name: string
    }
    sendToActiveUsersOnly: boolean
    abilityToSetTags: boolean
    footer: {
        id: string
        name: string
    }
    letterHead: {
        id: string
        name: string
    }
    topMargin: number
    bottomMargin: number
    pageMargin: number
    version: Version
}

export class LetterTemplateVerbose {
    id: string
    name: Localization[]
    description: Localization[]
    version: Version
    status: {
        id: string
        name: string
    }
    sendToActiveUsersOnly: boolean
    abilityToSetTags: boolean
    footer: {
        id: string
        name: string
    }
    letterHead: {
        id: string
        name: string
    }
    templateReplacements: {
        filter: string
        sort: string
        identifier: string
        timelineOption: {
            id: string
            name: string
        }
        fields: {
            id: string
            name: string
            format: string
        }[]
        table: {
            id: string
            name: string
        }
    }[]
    topMargin: number
    bottomMargin: number
    pageMargin: number
    html: string
}
export class LetterTemplateSubmit {
    id?: string
    changeReason: string
    changeReasonComments: string
    name: {
        culture: string
        text: string
    }[]
    description: {
        culture: string
        text: string
    }[]
    html: string
    status: string
    sendToActiveUsersOnly: boolean
    abilityToSetTags: boolean
    footer: string
    letterhead: string
    owners: string[]
    publishers: string[]
    organizations: string[]
    topMargin: number
    bottomMargin: number
    pageMargin: number
    templateReplacements: {
        filter: string
        sort: string
        identifier: string
        timelineOption: string
        fields: string[]
        table: string
    }[]
}

export class PageOrientation {
    id: string
    name: string
}

export class PaperSize {
    id: string
    name: string
}

export class RecipientType {
    id: string
    name: string
}

export class DocumentType {
    id: string
    name: string
}

export class DeliveryType {
    id: string
    name: string
}

export class TemplateStatus {
    id: string
    name: string
}

export class TemplateTimelineOption {
    id: string
    name: string
}

export class TemplateOwner {
    id: string
    owner: {
        id: string
        firstName: string
        lastName: string
    }
    template: {
        id: string
        name: string
    }
    version: Version
}

export class TemplatePublisher {
    id: string
    publisher: {
        id: string
        firstName: string
        lastName: string
    }
    template: {
        id: string
        name: string
    }
    version: Version
}

export class TemplateOrganization {
    id: string
    organization: {
        id: string
        firstName: string
        lastName: string
    }
    template: {
        id: string
        name: string
    }
    version: Version
}

export class Template {
    id: string
    name: string
    description: string
    status: TemplateStatus
    sendToActiveUsersOnly: boolean
    abilityToSetTags: boolean
    footer: {
        id: string
        name: string
    }
    letterHead: {
        id: string
        name: string
    }
    topMargin: number
    bottomMargin: number
    pageMargin: number
    version: Version
}

export class TemplateVerbose {
    id: string
    name: Localization[]
    description: Localization[]
    status: TemplateStatus
    sendToActiveUsersOnly: boolean
    abilityToSetTags: boolean
    footer: {
        id: string
        name: string
    }
    letterHead: {
        id: string
        name: string
    }
    topMargin: number
    bottomMargin: number
    pageMargin: number
    html: string
    version: Version
}

export class TemplateSubmit {
    name: Localization[]
    description: Localization[]
    status: string
    sendToActiveUsersOnly: boolean
    abilityToSetTags: boolean
    footer: {
        id: string
        name: string
    }
    letterHead: {
        id: string
        name: string
    }
    topMargin: number
    bottomMargin: number
    pageMargin: number
    html: string
    changeReason: string
    changeReasonComments: string
}

/*
.##.......########.########.########.########.########......######...########.##....##.########.########.....###....########.####..#######..##....##..######.
.##.......##..........##.......##....##.......##.....##....##....##..##.......###...##.##.......##.....##...##.##......##.....##..##.....##.###...##.##....##
.##.......##..........##.......##....##.......##.....##....##........##.......####..##.##.......##.....##..##...##.....##.....##..##.....##.####..##.##......
.##.......######......##.......##....######...########.....##...####.######...##.##.##.######...########..##.....##....##.....##..##.....##.##.##.##..######.
.##.......##..........##.......##....##.......##...##......##....##..##.......##..####.##.......##...##...#########....##.....##..##.....##.##..####.......##
.##.......##..........##.......##....##.......##....##.....##....##..##.......##...###.##.......##....##..##.....##....##.....##..##.....##.##...###.##....##
.########.########....##.......##....########.##.....##.....######...########.##....##.########.##.....##.##.....##....##....####..#######..##....##..######.
*/

export class LetterGeneration {
    id: string
    template: {
        id: string
        name: string
    }
    footer: {
        id: string
        name: string
    }
    header: {
        id: string
        name: string
    }
    fileName: string
    emailSubject: string
    emailBody: string
    publishedBy: {
        id: string
        firstName: string
        lastName: string
    }
    publishedOn: string
    paperSize: {
        id: string
        name: string
    }
    orientation: {
        id: string
        name: string
    }
    documentType: {
        id: string
        name: string
    }
    status: {
        id: string
        name: string
    }
    deliveryMethod: {
        id: string
        name: string
        sortOrder: number
        enabled: boolean
        version: Version
    }
    version: Version
}

export class LetterGenerationVerbose {
    id: string
    template: {
        id: string
        name: string
    }
    footer: {
        id: string
        name: string
    }
    header: {
        id: string
        name: string
    }
    fileName: string
    emailSubject: string
    emailBody: string
    publishedBy: {
        id: string
        firstName: string
        lastName: string
    }
    publishedOn: string
    paperSize: {
        id: string
        name: string
    }
    orientation: {
        id: string
        name: string
    }
    documentType: {
        id: string
        name: string
    }
    status: {
        id: string
        name: string
    }
    deliveryMethod: {
        id: string
        name: string
        sortOrder: number
        enabled: boolean
        version: Version
    }
    version: Version
}

export class LetterGenerationSubmit {
    id?: string
    template: string
    footer: string
    header: string
    fileName: string
    emailSubject: string
    emailBody: string
    paperSize: string
    orientation: string
    documentType: string
    deliveryMethod: string
    changeReason: string
    changeReasonComments: string
    originalCreatedOn: string
    asOf: string
}

export class LetterGenerationEmployee {
    employee: {
        id: string
        firstName: string
        lastName: string
    }
    letterGeneration: {
        id: string
    }
    letterGenerated: boolean
    version: Version
}

export class LetterGenerationEmployeeSubmit {
    employee: string
    letterGeneration: string
}

export class LetterGenerationEmployeeFromFilter {
    filter: string
}

export class LetterGenerationRecipient {
    id: string
    letterGeneration: {
        id: string
    }
    user: {
        id: string
        firstName: string
        lastName: string
    }
    recipientType: {
        id: string
        name: string
    }
    version: Version
}

export class LetterGenerationRecipientSubmit {
    changeReason: string
    changeReasonComments: string
    letterGeneration: string
    specifiedUser: string
    recipientType: string
}

export class LetterDeliveryMethod {
    id: string
    name: string
    sortOrder: number
    enabled: boolean
    version: Version
}

export class LetterDeliveryMethodVerbose {
    id: string
    name: string
    sortOrder: number
    enabled: boolean
    parameters: {
        key: string
        value: string
    }[]
    version: Version
}

export class LetterDeliveryMethodSubmit {
    id?: string
    changeReason: string
    changeReasonComments: string
    name: string
    sortOrder: number
    enabled: boolean
    parameters: {
        key: string
        value: string
    }[]
}