<h5 mat-dialog-title appLocalizationPopupDirective localizationCode="Integrations-Event-Details">{{ 'Integrations-Event-Details' | translate: {Default: "Integration Event Details"} }}</h5>

<mat-dialog-content>
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title appLocalizationPopupDirective localizationCode="Integrations-ViewPayload">
                    {{ 'Integrations-ViewPayload' | translate: {Default: "View Payload"} }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <pre>{{ getPrettyJson(payload) }}</pre>
        </mat-expansion-panel>

        <!-- Add more expansion panels here for logs if needed -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title appLocalizationPopupDirective localizationCode="Integrations-ViewLogs">
                    {{ 'Integrations-ViewLogs' | translate: {Default: "View Logs"} }}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <pre>{{ mergedMessages }}</pre>
        </mat-expansion-panel>
    </mat-accordion>
</mat-dialog-content>


<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">OK</button>
</mat-dialog-actions>
