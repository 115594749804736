<app-layout>
    <ng-container *ngIf="letterGeneration !== null && letterGeneration?.status?.id !== 'Draft'; else showEditor">
        <mat-card class="mat-elevation-z0">
            <mat-card-header>
                <mat-card-title>
                    <h4 appLocalizationPopupDirective [localizationCode]="'Letter-Generation-Published'" *ngIf="letterGeneration?.status?.id === 'Published'">
                        {{ 'Letter-Generation-Published' | translate: {Default: 'Letter Generation Published'} }}
                    </h4>
                    <h4 appLocalizationPopupDirective [localizationCode]="'Letter-Generation-Publishing'" *ngIf="letterGeneration?.status?.id === 'Queued'">
                        {{ 'Letter-Generation-Publishing' | translate: {Default: 'Letter Generation Publishing'} }}
                    </h4>
                </mat-card-title>
    
                <button mat-stroked-button setColor="primaryColour" color="primary" (click)="backToLetterGenerations()">
                    {{ 'Letter-Generation-Back' | translate: {Default: 'Back To Letter Generations'} }}
                </button>
            </mat-card-header>
    
            <mat-card-content>
                <div *ngIf="loadingLetterTemplate === false; else loadingTemplate" class="preview-container">
                    <div class="preview-details">

                        <div class="section">
                            <h5 appLocalizationPopupDirective [localizationCode]="'Letter-Generation-LetterDetails'">{{ 'Letter-Generation-PublishedDetails' | translate: {Default: 'Published Details'} }}</h5>

                            <div class="row">
                                <div class="label" appLocalizationPopupDirective [localizationCode]="'DeliveryDetails-PublishedBy'">
                                    {{ 'DeliveryDetails-PublishedBy' | translate: {Default: 'Published By'} }}
                                </div>
                                <mat-chip-list>
                                    <mat-chip>
                                        <app-employee-image matChipAvatar [employeeId]="letterGeneration?.publishedBy?.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>
                        
                                        <span class="employee">
                                            {{letterGeneration?.publishedBy?.firstName}} {{letterGeneration?.publishedBy?.lastName}}
                                        </span>
                                    </mat-chip>
                                </mat-chip-list>
                            </div>

                            <div class="row">
                                <div class="label" appLocalizationPopupDirective [localizationCode]="'DeliveryDetails-PublishedOn'">
                                    {{ 'DeliveryDetails-PublishedOn' | translate: {Default: 'Published On'} }}
                                </div>
                                <div class="value">{{ letterGeneration?.publishedOn | date:'medium'  }}</div>
                            </div>
                        </div>

                        <div class="section">
                            <h5 appLocalizationPopupDirective [localizationCode]="'Letter-Generation-LetterDetails'">{{ 'Letter-Generation-LetterDetails' | translate: {Default: 'Letter Details'} }}</h5>

                            <div class="row">
                                <div class="label" appLocalizationPopupDirective [localizationCode]="'Selectedtemplate-Name'">
                                    {{ 'Selectedtemplate-Name' | translate: {Default: 'Template Name'} }}
                                </div>
                                <div class="value">{{selectedTemplates[0]?.name}}</div>
                            </div>

                            <div class="row">
                                <div class="label" appLocalizationPopupDirective [localizationCode]="'Selectedtemplate-Description'">
                                    {{ 'Selectedtemplate-Description' | translate: {Default: 'Template Description'} }}
                                </div>
                                <div class="value">{{selectedTemplates[0]?.description}}</div>
                            </div>
                        </div>

                        <div class="section">
                            <h5  appLocalizationPopupDirective [localizationCode]="'Letter-Generation-SelectedEmployees'">{{ 'Letter-Generation-SelectedEmployees' | translate: {Default: 'Selected Employees'} }}</h5>

                            <button 
                                *ngIf="letterGeneration?.status?.id === 'Published'"
                                mat-raised-button 
                                setColor="primaryColour" 
                                color="primary" 
                                class="download-all-button"
                                appLocalizationPopupDirective [localizationCode]="'Letter-Generation-DownloadAll'"
                                (click)="downloadAllLetters()"
                            >
                                <mat-icon class="material-icons-outlined">file_download</mat-icon>
                                {{ 'Letter-Generation-DownloadAll' | translate: {Default: 'Download All'} }}
                            </button>

                            <mat-chip-list>
                                <div *ngIf="employeesNotBeingRemoved.length === 0">
                                    No employees selected
                                </div>

                                <ng-container *ngFor="let item of employeesNotBeingRemoved">
                                    <mat-chip class="employee-chip">
                                        <app-employee-image matChipAvatar [employeeId]="item.employee.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>
                        
                                        <span class="employee">
                                            {{item?.employee.firstName}} {{item?.employee.lastName}}
                                        </span>

                                        <button 
                                            *ngIf="letterGeneration?.status?.id === 'Published'"
                                            class="settings-button" 
                                            mat-mini-fab 
                                            [matMenuTriggerFor]="settings" 
                                            appPreventDoubleClick 
                                            (throttledClick)="$event.stopPropagation()"
                                        >
                                            <mat-icon class="settings-button__icon material-icons-outlined">more_vert</mat-icon>
                                        </button>
                                        <mat-menu #settings="matMenu" xPosition="before">
                                            <button 
                                                class="settings-menu__item material-icons-outlined" 
                                                appPreventDoubleClick 
                                                (throttledClick)="$event.stopPropagation(); downloadLetter(item.employee.id);" 
                                                mat-menu-item
                                            >
                                                <mat-icon class="settings-button__icon material-icons-outlined">file_download</mat-icon>
                                                {{ 'Download-Letter' | translate: {Default: "Download-Letter"} }}
                                            </button>
                                        </mat-menu>
                                    </mat-chip>

                                    <!-- <mat-icon 
                                        *ngIf="letterGeneration?.status?.id === 'Published'"
                                        setColor 
                                        [setColorRules]="[
                                            {
                                                color: 'primaryColour',
                                                events: ['hover']
                                            }
                                        ]" 
                                        (click)="downloadLetter(item.employee.id)"
                                        class="material-icons-outlined clickable-icon"
                                        [matTooltip]="translate.instant('Download-Letter')"
                                    >file_download</mat-icon> -->

                                </ng-container>
                            </mat-chip-list>
                        </div>

                        <div class="section" *ngIf="deliveryDetailsFormData?.deliveryMethod === 'Email'">
                            <h5  appLocalizationPopupDirective [localizationCode]="'Letter-Generation-SelectedRecipients'">{{ 'Letter-Generation-SelectedRecipients' | translate: {Default: 'Selected Recipients'} }}</h5>

                            <mat-chip-list>
                                <div *ngIf="recipientsNotBeingRemoved.length === 0">
                                    No recipients selected
                                </div>
                                <ng-container *ngFor="let item of recipientsNotBeingRemoved">
                                    <mat-chip>
                                        <app-employee-image matChipAvatar [employeeId]="item.user?.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>
                        
                                        <span class="employee">
                                            <ng-container *ngIf="item?.user !== null; else showRecipientType">
                                                {{item?.user?.firstName}} {{item?.user?.lastName}}
                                            </ng-container>
                                            <ng-template #showRecipientType>
                                                {{ item.recipientType?.name }}
                                            </ng-template>
                                        </span>
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>

                        <div class="section">
                            <h5 appLocalizationPopupDirective [localizationCode]="'Letter-Generation-Delivery-Details'">{{ 'Letter-Generation-Delivery-Details' | translate: {Default: 'Delivery Details'} }}</h5>

                            <div class="row">
                                <div class="label" appLocalizationPopupDirective [localizationCode]="'DeliveryDetails-FileName'">
                                    {{ 'DeliveryDetails-FileName' | translate: {Default: 'File Name'} }}
                                </div>
                                <div class="value">{{deliveryDetailsFormData?.fileName}}</div>
                            </div>
                            <div class="row">
                                <div class="label" appLocalizationPopupDirective [localizationCode]="'DeliveryDetails-PaperSize'">
                                    {{ 'DeliveryDetails-PaperSize' | translate: {Default: 'Paper Size'} }}
                                </div>
                                <div class="value">{{deliveryDetailsFormData?.paperSize}}</div>
                            </div>
                            <div class="row">
                                <div class="label" appLocalizationPopupDirective [localizationCode]="'DeliveryDetails-Orientation'">
                                    {{ 'DeliveryDetails-Orientation' | translate: {Default: 'Orientation'} }}
                                </div>
                                <div class="value">{{deliveryDetailsFormData?.orientation}}</div>
                            </div>
                            <div class="row">
                                <div class="label" appLocalizationPopupDirective [localizationCode]="'DeliveryDetails-DocumentType'">
                                    {{ 'DeliveryDetails-DocumentType' | translate: {Default: 'Document Type'} }}
                                </div>
                                <div class="value">{{deliveryDetailsFormData?.documentType}}</div>
                            </div>
                            <div class="row">
                                <div class="label" appLocalizationPopupDirective [localizationCode]="'DeliveryDetails-DeliveryMethod'">
                                    {{ 'DeliveryDetails-DeliveryMethod' | translate: {Default: 'Delivery Method'} }}
                                </div>
                                <div class="value">{{deliveryDetailsFormData?.deliveryMethod}}</div>
                            </div>
                            <div class="row" *ngIf="deliveryDetailsFormData?.deliveryMethod === 'Email'">
                                <div class="label" appLocalizationPopupDirective [localizationCode]="'DeliveryDetails-EmailSubject'">
                                    {{ 'DeliveryDetails-EmailSubject' | translate: {Default: 'Email Subject'} }}
                                </div>
                                <div class="value">{{emailDetailsFormData?.emailSubject}}</div>
                            </div>
                            <div class="row" *ngIf="deliveryDetailsFormData?.deliveryMethod === 'Email'">
                                <div class="label" appLocalizationPopupDirective [localizationCode]="'DeliveryDetails-EmailBody'">
                                    {{ 'DeliveryDetails-EmailBody' | translate: {Default: 'Email Body'} }}
                                </div>
                                <div class="value" [innerHTML]="emailDetailsFormData?.emailBody"></div>
                            </div>
                        </div>

                    </div>
                    <div class="preview-letter" *ngIf="letterTemplate">

                        <div class="letter" [ngStyle]="{
                            'margin-top': letterTemplate?.topMargin ? letterTemplate.topMargin + 'px' : '0px', 
                            'margin-bottom': letterTemplate?.bottomMargin ? letterTemplate.bottomMargin + 'px' : '0px',
                            'margin-left': letterTemplate?.pageMargin ? letterTemplate.pageMargin + 'px' : '0px',
                            'margin-right': letterTemplate?.pageMargin ? letterTemplate.pageMargin + 'px' : '0px'
                        }" >
                            <div class="letterhead" [innerHTML]="letterHead?.html"></div>
                            <div class="letter-content" [innerHTML]="letterTemplate?.html"></div>
                            <div class="letter-footer" [innerHTML]="letterFooter?.html"></div>
                        </div>

                    </div>
                </div>

                <ng-template #loadingTemplate>
                    <div class="loading">
                        <ngx-skeleton-loader
                            count="5"
                            [theme]="{ 
                                'height.px': 50
                            }"
                        ></ngx-skeleton-loader>
                    </div>
                </ng-template>
                
            </mat-card-content>
        </mat-card>
    </ng-container>
    <ng-template #showEditor>
        <mat-card class="mat-elevation-z0">
            <mat-card-header>
                <mat-card-title>
                    <h4 appLocalizationPopupDirective [localizationCode]="'Letter-Generation-Editor'">
                        {{ 'Letter-Generation-Editor' | translate: {Default: 'Letter Generation Editor'} }}
                    </h4>
                </mat-card-title>
    
                <button mat-stroked-button setColor="primaryColour" color="primary" (click)="backToLetterGenerations()">
                    {{ 'Letter-Generation-Back' | translate: {Default: 'Back To Letter Generations'} }}
                </button>
            </mat-card-header>
            <mat-card-content>
                
                <ng-container *ngIf="loadingGeneration; else loaded">
                    <div class="loading">
                        <ngx-skeleton-loader count="10" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
                    </div>
                </ng-container>
                <ng-template #loaded>
                    <mat-stepper linear #stepper (selectionChange)="stepChange($event)">
                        <ng-template matStepperIcon="edit"><mat-icon style="margin-top: 3px; margin-left: 4px;" class="material-icons-outlined">create</mat-icon></ng-template>
                        
                        <mat-step>
                            <ng-template matStepLabel appLocalizationPopupDirective [localizationCode]="'Letter-Generation-Step1'">
                                {{ 'Letter-Generation-Step1' | translate: {Default: 'Select Letter'} }}
                            </ng-template>
    
                            <div *ngIf="this.selectedTemplates.length > 0" class="selected-template">
                                <app-bootstrap-alert 
                                    *ngIf="selectedTemplates[0]?.status?.id !== 'Active'"
                                    [text]="translate.instant('InactiveTemplateSelectedWarning')" 
                                    localizationCode="InactiveTemplateSelectedWarning" 
                                    type="danger">
                                </app-bootstrap-alert>

                                <h5>{{ 'Letter-Generation-SelectedTemplate' | translate: {Default: 'Selected Template'} }}</h5>

                                <div>{{ this.selectedTemplates[0]?.name }}</div>
                            </div>
                            
                            <app-data-grid-comp
                                *ngIf="letterGeneration === null"
                                [tableId]="db_tables.LetterTemplates"
                                [columns]="letterTemplatesColumns"
                                [filterCategories]="letterTemplatesFilterCategories"
                                [gridDataResult]="letterTemplatesGridDataResult"
                                [isLoading]="isLoadingLetterTemplates"
                                [pageSize]="pageSize"
                                hideActionButtons="true"
                                [skip]="skip"
                                category="Letters-Templates"
                                [rowSelectionSettings]="{
                                    canSelectRows: true,
                                    selectableMode: 'single',
                                    selectRowBy: 'id'
                                }"
                                clickableRows="true"
                                searchable="true"
                                [sortable]="{
                                    mode: 'multiple'
                                }"
                                [sortableColumns]="letterTemplatesSortableColumns"
                                [selectedRowsButtonSettings]="{
                                    show: false,
                                    text: 'DeleteAllSelected',
                                    icon: 'delete'
                                }"
                                [rowActionButtons]="gridActionButtons"
                                (rowActionEvent)="previewTemplate($event)"
                                (emitPageChangeEvent)="pageChange($event)"
                                (emitSearchEvent)="search($event)"
                                (emitSelectedRows)="selectedRows($event)"
                                (emitFilterEvent)="filterCallback($event)"
                                (emitSortEvent)="sortChange($event)"
                            ></app-data-grid-comp>
    
    
                            <app-form-generator 
                                formId="frm_f1SlYcx0H0TIeG"
                                [formData]="headerFooterFormData"
                                (emitFormData)="formDataEmitted($event, 'headerFooter')"
                                (emitFormDataChange)="formDataEmitted($event, 'headerFooter')"
                                (emitFormPristine)="formPristineEmitted($event, 'headerFooter')"
                                (emitFormStatus)="formStatusUpdated($event, 'headerFooter')"
                            ></app-form-generator>
                            
                            <div class="buttons-container">
                                <button mat-raised-button matStepperNext setColor="primaryColour" color="primary" [disabled]="!step1Valid || this.selectedTemplates.length === 0">{{ 'Next' | translate: {Default: 'Next'} }}</button>
                            </div>
                        </mat-step>
    
                        <mat-step>
                            <ng-template matStepLabel appLocalizationPopupDirective [localizationCode]="'Letter-Generation-Step2'">
                                {{ 'Letter-Generation-Step2' | translate: {Default: 'Select Employees'} }}
                            </ng-template>
    
                            <div class="employees-section">
                                <button mat-stroked-button setColor="primaryColour" color="primary" (click)="showAddUserModal('employees')">
                                    <mat-icon class="material-icons-outlined">add</mat-icon> {{ 'AddEmployees' | translate: {Default: 'Add Employees'} }}
                                </button>

                                <mat-form-field class="search-field" appearance="outline" >
                                    <input matInput
                                           [(ngModel)]="employeeListSearchValue"
                                           placeholder="Search...">
                                    <mat-icon matPrefix class="material-icons-outlined">
                                        search
                                    </mat-icon>
                                </mat-form-field>
    
                                <div appLocalizationPopupDirective [localizationCode]="'NoEmployeesSelected'" *ngIf="employeesNotBeingRemoved.length === 0">
                                    {{ 'NoEmployeesSelected' | translate: {Default: 'No employees selected'} }}
                                </div>
    
                                <mat-list role="list">
                                    <ng-container *ngFor="let employee of employeesNotBeingRemoved | employeeListSearch:employeeListSearchValue; let first">
                                        
                                        <mat-list-item role="listitem">
                                            <div class="employee-name">
                                                <app-employee-image [employeeId]="employee.employee?.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>
        
                                                <span class="employee">
                                                    {{employee.employee?.firstName}} {{employee.employee?.lastName}}
                                                </span>
                                            </div>
        
                                            <mat-chip-list>
                                                <mat-chip 
                                                    [ngClass]="{
                                                        'red-chip': employee.status?.id === 'remove',
                                                        'green-chip': employee.status?.id === 'current',
                                                        'orange-chip': employee.status?.id === 'draft'
                                                    }"
                                                >{{ employee.status?.text }}</mat-chip>
                                            </mat-chip-list>
        
                                            <button class="settings-button" mat-mini-fab [matMenuTriggerFor]="settings" appPreventDoubleClick (throttledClick)="$event.stopPropagation()">
                                                <mat-icon class="settings-button__icon material-icons-outlined">more_vert</mat-icon>
                                            </button>
                                            <mat-menu #settings="matMenu" xPosition="before">
                                                <button class="settings-menu__item material-icons-outlined" appPreventDoubleClick (throttledClick)="$event.stopPropagation(); removeEmployee(employee);" mat-menu-item>
                                                    <mat-icon class="settings-button__icon material-icons-outlined">delete</mat-icon>
                                                    {{ 'Remove' | translate: {Default: "Remove"} }}
                                                </button>
                                            </mat-menu>
                                        </mat-list-item>
    
                                        <mat-divider></mat-divider>
    
                                    </ng-container>
                                </mat-list>


                            </div>
                            
                            <div class="buttons-container">
                                <button mat-raised-button matStepperNext setColor="primaryColour" color="primary" [disabled]="employeesNotBeingRemoved.length === 0">{{ 'Next' | translate: {Default: 'Next'} }}</button>
                                <button mat-raised-button matStepperPrevious>{{ 'Back' | translate: {Default: 'Back'} }}</button>
                            </div>
                        </mat-step>
    
                        <mat-step>
                            <ng-template matStepLabel appLocalizationPopupDirective [localizationCode]="'Letter-Generation-Step3'">
                                {{ 'Letter-Generation-Step3' | translate: {Default: 'Delivery Details'} }}
                            </ng-template>
    
                            <app-form-generator 
                                formId="frm_FvcEXc3i59LtD0"
                                [formData]="deliveryDetailsFormData"
                                (emitFormData)="formDataEmitted($event, 'deliveryDetails')"
                                (emitFormDataChange)="formDataEmitted($event, 'deliveryDetails')"
                                (emitFormPristine)="formPristineEmitted($event, 'deliveryDetails')"
                                (emitFormStatus)="formStatusUpdated($event, 'deliveryDetails')"
                            ></app-form-generator>

                            <ng-container *ngIf="deliveryDetailsFormData?.deliveryMethod === 'Email'">
                                <div class="recipients">
                                    <button mat-stroked-button setColor="primaryColour" color="primary" (click)="openRecipientDialog()">
                                        <mat-icon class="material-icons-outlined">add</mat-icon> {{ 'AddRecipients' | translate: {Default: 'Add Recipients'} }}
                                    </button>
    
                                    <mat-form-field class="search-field" appearance="outline" >
                                        <input matInput
                                               [(ngModel)]="recipientsListSearchValue"
                                               placeholder="Search...">
                                        <mat-icon matPrefix class="material-icons-outlined">
                                            search
                                        </mat-icon>
                                    </mat-form-field>
        
                                    <div *ngIf="recipientsNotBeingRemoved.length === 0">
                                        No recipients selected
                                    </div>
        
                                    <mat-list role="list">
                                        <ng-container *ngFor="let employee of recipientsNotBeingRemoved | recipientListSearch:recipientsListSearchValue; let first">
                                            
                                            <mat-list-item role="listitem">
                                                <div class="employee-name" *ngIf="employee.user !== null">
                                                    <app-employee-image [employeeId]="employee.user?.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>
            
                                                    <span class="employee">
                                                        {{employee.user?.firstName}} {{employee.user?.lastName}}
                                                    </span>
                                                </div>
    
                                                
                                                <div>
                                                    {{employee.recipientType?.name}}
                                                </div>
            
                                                <mat-chip-list>
                                                    <mat-chip 
                                                        [ngClass]="{
                                                            'red-chip': employee.status?.id === 'remove',
                                                            'green-chip': employee.status?.id === 'current',
                                                            'orange-chip': employee.status?.id === 'draft'
                                                        }"
                                                    >{{ employee.status?.text }}</mat-chip>
                                                </mat-chip-list>
            
                                                <button class="settings-button" mat-mini-fab [matMenuTriggerFor]="settings" appPreventDoubleClick (throttledClick)="$event.stopPropagation()">
                                                    <mat-icon class="settings-button__icon material-icons-outlined">more_vert</mat-icon>
                                                </button>
                                                <mat-menu #settings="matMenu" xPosition="before">
                                                    <button class="settings-menu__item material-icons-outlined" appPreventDoubleClick (throttledClick)="$event.stopPropagation(); removeRecipient(employee);" mat-menu-item>
                                                        <mat-icon class="settings-button__icon material-icons-outlined">delete</mat-icon>
                                                        {{ 'Remove' | translate: {Default: "Remove"} }}
                                                    </button>
                                                </mat-menu>
                                            </mat-list-item>
                                            <mat-divider></mat-divider>
        
                                        </ng-container>
                                    </mat-list>
                                </div>

                                <app-form-generator
                                    formId="frm_hSUUvnj70Lw28J"
                                    [formData]="emailDetailsFormData"
                                    (emitFormData)="formDataEmitted($event, 'emailDetails')"
                                    (emitFormDataChange)="formDataEmitted($event, 'emailDetails')"
                                    (emitFormPristine)="formPristineEmitted($event, 'emailDetails')"
                                    (emitFormStatus)="formStatusUpdated($event, 'emailDetails')"
                                ></app-form-generator>
                            </ng-container>
    
                            
                            <div class="buttons-container">
                                <button mat-raised-button matStepperNext setColor="primaryColour" color="primary" [disabled]="!isStep3Valid">{{ 'Next' | translate: {Default: 'Next'} }}</button>
                                <button mat-raised-button matStepperPrevious>{{ 'Back' | translate: {Default: 'Back'} }}</button>
                            </div>
                        </mat-step>
    
                        <mat-step>
                            <ng-template *ngIf="letterGeneration !== null" matStepLabel appLocalizationPopupDirective [localizationCode]="'Letter-Generation-Step4'">{{ 'Letter-Generation-Step4' | translate: {Default: 'Review and Publish'} }}</ng-template>
                            <ng-template *ngIf="letterGeneration === null" matStepLabel appLocalizationPopupDirective [localizationCode]="'Letter-Generation-Step-Save'">{{ 'Letter-Generation-Step-Save' | translate: {Default: 'Review and Save'} }}</ng-template>
                            
                            <ng-template matStepContent>
                                <ng-container *ngIf="letterTemplate; else noPreviewAvailable">
                                    <div class="preview-container">
                                        <div class="preview-details">
            
                                            <div class="section">
                                                <h5 appLocalizationPopupDirective [localizationCode]="'Letter-Generation-LetterDetails'">{{ 'Letter-Generation-LetterDetails' | translate: {Default: 'Letter Details'} }}</h5>
                                                
                                                <div class="row">
                                                    <div class="label" appLocalizationPopupDirective [localizationCode]="'Selectedtemplate-Name'">
                                                        {{ 'Selectedtemplate-Name' | translate: {Default: 'Template Name'} }}
                                                    </div>
                                                    <div class="value">{{selectedTemplates[0]?.name}}</div>
                                                </div>
                    
                                                <div class="row">
                                                    <div class="label" appLocalizationPopupDirective [localizationCode]="'Selectedtemplate-Description'">
                                                        {{ 'Selectedtemplate-Description' | translate: {Default: 'Template Description'} }}
                                                    </div>
                                                    <div class="value">{{selectedTemplates[0]?.description}}</div>
                                                </div>
                                            </div>
            
                                            <div class="section">
                                                <h5  appLocalizationPopupDirective [localizationCode]="'Letter-Generation-SelectedEmployees'">{{ 'Letter-Generation-SelectedEmployees' | translate: {Default: 'Selected Employees'} }}</h5>
            
                                                <mat-chip-list>
                                                    <div *ngIf="employeesNotBeingRemoved.length === 0">
                                                        No employees selected
                                                    </div>
                                                    <ng-container *ngFor="let item of employeesNotBeingRemoved">
                                                        <mat-chip>
                                                            <app-employee-image matChipAvatar [employeeId]="item.employee.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>
                                            
                                                            <span class="employee">
                                                                {{item?.employee.firstName}} {{item?.employee.lastName}}
                                                            </span>
                                                        </mat-chip>
                                                    </ng-container>
                                                </mat-chip-list>
                                            </div>
        
                                            <div class="section" *ngIf="deliveryDetailsFormData?.deliveryMethod === 'Email'">
                                                <h5  appLocalizationPopupDirective [localizationCode]="'Letter-Generation-SelectedRecipients'">{{ 'Letter-Generation-SelectedRecipients' | translate: {Default: 'Selected Recipients'} }}</h5>
            
                                                <mat-chip-list>
                                                    <div *ngIf="recipientsNotBeingRemoved.length === 0">
                                                        No recipients selected
                                                    </div>
                                                    <ng-container *ngFor="let item of recipientsNotBeingRemoved">
                                                        <mat-chip>
                                                            <app-employee-image matChipAvatar [employeeId]="item.user?.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>
                                            
                                                            <span class="employee">
                                                                <ng-container *ngIf="item?.user !== null; else showRecipientType">
                                                                    {{item?.user?.firstName}} {{item?.user?.lastName}}
                                                                </ng-container>
                                                                <ng-template #showRecipientType>
                                                                    {{ item.recipientType?.name }}
                                                                </ng-template>
                                                            </span>
                                                        </mat-chip>
                                                    </ng-container>
                                                </mat-chip-list>
                                            </div>
            
                                            <div class="section">
                                                <h5 appLocalizationPopupDirective [localizationCode]="'Letter-Generation-Delivery-Details'">{{ 'Letter-Generation-Delivery-Details' | translate: {Default: 'Delivery Details'} }}</h5>
        
                                                <div class="row">
                                                    <div class="label" appLocalizationPopupDirective [localizationCode]="'DeliveryDetails-FileName'">
                                                        {{ 'DeliveryDetails-FileName' | translate: {Default: 'File Name'} }}
                                                    </div>
                                                    <div class="value">{{deliveryDetailsFormData?.fileName}}</div>
                                                </div>
                                                <div class="row">
                                                    <div class="label" appLocalizationPopupDirective [localizationCode]="'DeliveryDetails-PaperSize'">
                                                        {{ 'DeliveryDetails-PaperSize' | translate: {Default: 'Paper Size'} }}
                                                    </div>
                                                    <div class="value">{{deliveryDetailsFormData?.paperSize}}</div>
                                                </div>
                                                <div class="row">
                                                    <div class="label" appLocalizationPopupDirective [localizationCode]="'DeliveryDetails-Orientation'">
                                                        {{ 'DeliveryDetails-Orientation' | translate: {Default: 'Orientation'} }}
                                                    </div>
                                                    <div class="value">{{deliveryDetailsFormData?.orientation}}</div>
                                                </div>
                                                <div class="row">
                                                    <div class="label" appLocalizationPopupDirective [localizationCode]="'DeliveryDetails-DocumentType'">
                                                        {{ 'DeliveryDetails-DocumentType' | translate: {Default: 'Document Type'} }}
                                                    </div>
                                                    <div class="value">{{deliveryDetailsFormData?.documentType}}</div>
                                                </div>
                                                <div class="row">
                                                    <div class="label" appLocalizationPopupDirective [localizationCode]="'DeliveryDetails-DeliveryMethod'">
                                                        {{ 'DeliveryDetails-DeliveryMethod' | translate: {Default: 'Delivery Method'} }}
                                                    </div>
                                                    <div class="value">{{deliveryDetailsFormData?.deliveryMethod}}</div>
                                                </div>
                                                <div class="row"  *ngIf="deliveryDetailsFormData?.deliveryMethod === 'Email'">
                                                    <div class="label" appLocalizationPopupDirective [localizationCode]="'DeliveryDetails-EmailSubject'">
                                                        {{ 'DeliveryDetails-EmailSubject' | translate: {Default: 'Email Subject'} }}
                                                    </div>
                                                    <div class="value">{{emailDetailsFormData?.emailSubject}}</div>
                                                </div>
                                                <div class="row"  *ngIf="deliveryDetailsFormData?.deliveryMethod === 'Email'">
                                                    <div class="label" appLocalizationPopupDirective [localizationCode]="'DeliveryDetails-EmailBody'">
                                                        {{ 'DeliveryDetails-EmailBody' | translate: {Default: 'Email Body'} }}
                                                    </div>
                                                    <div class="value" [innerHTML]="emailDetailsFormData?.emailBody"></div>
                                                </div>
                                            </div>
            
                                        </div>
                                        <div class="preview-letter" *ngIf="letterTemplate">
            
                                            <div class="letter" [ngStyle]="{
                                                'margin-top': letterTemplate?.topMargin ? letterTemplate.topMargin + 'px' : '0px', 
                                                'margin-bottom': letterTemplate?.bottomMargin ? letterTemplate.bottomMargin + 'px' : '0px',
                                                'margin-left': letterTemplate?.pageMargin ? letterTemplate.pageMargin + 'px' : '0px',
                                                'margin-right': letterTemplate?.pageMargin ? letterTemplate.pageMargin + 'px' : '0px'
                                            }" >
                                                <div class="letterhead" [innerHTML]="letterHead?.html"></div>
                                                <div class="letter-content" [innerHTML]="letterTemplate.html"></div>
                                                <div class="letter-footer" [innerHTML]="letterFooter?.html"></div>
                                            </div>
            
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #noPreviewAvailable>
                                    <h6 appLocalizationPopupDirective [localizationCode]="'NoLetterGenPreviewAvailable'">
                                        {{ 'NoLetterGenPreviewAvailable' | translate: {Default: "More information is needed to display a preview"} }}
                                    </h6>
                                </ng-template>
                                
                                <div class="buttons-container">
                                    <button mat-raised-button appLocalizationPopupDirective [localizationCode]="'SaveAsDraft'" setColor="primaryColour" color="primary" matStepperNext *ngIf="letterTemplate" (click)="save()">{{ 'SaveAsDraft' | translate: {Default: 'Save As Draft'} }}</button>
                                    <button mat-raised-button appLocalizationPopupDirective [localizationCode]="'SaveAndPublish'" setColor="primaryColour" color="primary" matStepperNext *ngIf="letterTemplate" (click)="save('publish')">{{ 'SaveAndPublish' | translate: {Default: 'Save And Publish'} }}</button>
                                    <button mat-raised-button matStepperPrevious>{{ 'Back' | translate: {Default: 'Back'} }}</button>
                                </div>
                            </ng-template>
        
                        </mat-step>
    
                    </mat-stepper>
                </ng-template>
    
            </mat-card-content>
        </mat-card>
    </ng-template>

    

