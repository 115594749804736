<h5 mat-dialog-title appLocalizationPopupDirective localizationCode="Integrations-Mappings">{{ 'Integrations-Mappings' | translate: {Default: "Integration Mapping"} }}</h5>

<mat-dialog-content>
    <form class="form" [formGroup]="form">

        <mat-form-field appLocalizationPopupDirective localizationCode="Integrations-FieldName" appearance="fill">
            <mat-label>
                {{ 'Integrations-FieldName' | translate: {Default: "Field Name"} }}
            </mat-label>

            <input
                matInput
                type="text"
                formControlName="externalField">
        </mat-form-field>

        <mat-form-field appLocalizationPopupDirective localizationCode="Integrations-Table" appearance="fill">
            <mat-label>{{ 'Integrations-Table' | translate: {Default: "Table"} }}</mat-label>
            <mat-select disableOptionCentering [(value)]="selectedTable" (valueChange)="handleSelectTableClick($event)" class="mat-form-field">
                <mat-option>-- None --</mat-option>

                <mat-optgroup *ngFor="let group of tableGroups" [label]="group.groupName">
                    <mat-option *ngFor="let table of group.tables" [value]="table">
                        {{table.name}}
                    </mat-option>
                </mat-optgroup>

            </mat-select>
        </mat-form-field>


        <mat-form-field appLocalizationPopupDirective localizationCode="Integrations-Field" appearance="fill" *ngIf="selectedTable != null">
            <mat-label>{{ 'Integrations-Field' | translate: {Default: "Field"} }}</mat-label>
            <mat-select disableOptionCentering [(value)]="selectedField" (valueChange)="handleSelectFieldClick($event)">
                <mat-option *ngFor="let field of fieldList" [value]="field">
                    {{field.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <div appLocalizationPopupDirective localizationCode="Active" class="slide-toggle-container" *ngIf="selectedField != null" >
            <mat-slide-toggle setColor="primaryColour" formControlName="status">
                {{ 'Active' | translate: {Default: "Active"} }}
            </mat-slide-toggle>
        </div>

        <br>

        <div appLocalizationPopupDirective localizationCode="Integrations-Mandatory" class="slide-toggle-container" *ngIf="selectedField != null">
            <mat-slide-toggle setColor="primaryColour" formControlName="mandatory">
                {{ 'Integrations-Mandatory' | translate: {Default: "Mandatory"} }}
            </mat-slide-toggle>
        </div>

        <br>

    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">{{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>
