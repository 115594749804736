<mat-card *appTableAccess="[tableReadAccessObj]">
    <mat-card-title class="header">
        <h5 *ngIf="category" appLocalizationPopupDirective [localizationCode]="category">
            {{ translate.instant(category) }}
        </h5>
        <div *ngIf="!hideActionButtons">
            <button
                *appTableAccess="[tableCreateAccessObj]"
                mat-flat-button
                class="action-button"
                setColor="primaryColour"
                color="primary"
                (click)="emitOpenDialogEvent.emit()"
            >
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon>
                <ng-container *ngIf="actionButtonText; else defaultActionButtonText">
                    <span class="button-text" appLocalizationPopupDirective [localizationCode]="actionButtonText">{{ translate.instant(actionButtonText) }}</span>
                </ng-container>
                <ng-template #defaultActionButtonText>
                    <span class="button-text" appLocalizationPopupDirective localizationCode="CreateNew">{{ 'CreateNew' | translate: {Default: "Create new"} }}</span>
                </ng-template>
            </button>
        </div>
    </mat-card-title>
    <mat-card-content>
        <kendo-grid
            *appTableAccess="[tableReadAccessObj]"
            [data]="gridDataResult"
            [pageSize]="pageSize"
            [skip]="skip"
            [pageable]="pageable"
            [style.maxHeight.vh]="maxHeight"
            [resizable]="true"
            [selectable]="{enabled: true, checkboxOnly: true, mode: rowSelectionSettings?.selectableMode}"
            [kendoGridSelectBy]="rowSelectionSettings.selectRowBy"
            [(selectedKeys)]="selectedItems"
            [loading]="isLoading"
            (pageChange)="emitPageChangeEvent.emit($event)"
            (cellClick)="cellClickHandler($event)"
            (excelExport)="onExcelExport($event)"
            [rowClass]="rowCallback"
            [scrollable]="scrollable"
            [rowHeight]="rowHeight"
            [height]="height"
            [filterable]="filterable"
            [filter]="filter"

            [sortable]="sortable"
            [sort]="sort"
            (sortChange)="sortChange($event)"

            [rowClass]="selectionToggleCallback"

            (dataStateChange)="emitDataStateChangeEvent.emit($event)"

            (selectionChange)="emitSelectedRows.emit($event)"
        >
            <ng-template kendoGridToolbarTemplate>
                <button 
                    *ngIf="showRefreshButton" 
                    mat-stroked-button 
                    setColor="primaryColour" 
                    color="primary" 
                    (click)="emitRefreshEvent.emit(true)" 
                    appLocalizationPopupDirective 
                    [localizationCode]="'Refresh'"
                >
                    <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="refresh icon">refresh</mat-icon>
                    {{ 'Refresh' | translate: {Default: "Refresh"} }}
                </button>

                <div>
                    <button *ngIf="export === 'excel'" class="export-to-excel-button" kendoGridExcelCommand appLocalizationPopupDirective localizationCode="ExportToExcel">
                        <img
                            class="ms-icon"
                            src="assets/icons/excel-icon.svg"
                            style="width: 35px;
                                        height: 35px;
                                        vertical-align: middle;">
                        {{ 'ExportToExcel' | translate: {Default: "Export to Excel"} }}
                    </button>
                </div>

                <div
                    *ngIf="selectedRowsButtonSettings.show === true && selectedItems.length > 0 && rowSelectionSettings?.showDeleteAllButton !== false"
                    appLocalizationPopupDirective
                    [localizationCode]="selectedRowsButtonSettings.text"
                    class="delete-all-selected-button-container"
                >
                    <button class="delete-all-selected-button" mat-stroked-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openConfirmDeleteDialog()">
                        <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">{{ selectedRowsButtonSettings.icon }}</mat-icon>
                        {{ translate.instant(selectedRowsButtonSettings.text) }} ({{selectedItems.length}})
                    </button>
                </div>


                <div class="search-container" *ngIf="searchable" appLocalizationPopupDirective localizationCode="Search">
                    <mat-form-field class="search-field" appearance="outline">
                        <input
                            matInput
                            [placeholder]="translate.instant('Search')"
                            [(ngModel)]="searchValue"
                            (input)="emitSearchEvent.emit(searchValue)"
                        >
                        <mat-icon matPrefix class="material-icons-outlined">
                            search
                        </mat-icon>
                    </mat-form-field>
                </div>

                <div class="mt-2" *ngIf="filterToggleDetails" appLocalizationPopupDirective [localizationCode]="filterToggleDetails?.text">
                    <mat-slide-toggle
                        setColor="primaryColour"
                        [checked]="filterToggleDetails?.checked"
                        disabled="false"
                        (change)="emitToggleFilter.emit($event)"
                    >
                        <span>{{ translate.instant(filterToggleDetails?.text) }}</span>
                    </mat-slide-toggle>
                </div>

                <ng-container *ngIf="filterCategories?.length > 0">
                    <filter-control [categories]="filterCategories" (callback)="emitFilterEvent.emit($event)"></filter-control>
                </ng-container>

            </ng-template>

            <ng-template kendoGridCellLoadingTemplate>
                <div class="k-loading-color"></div>
                <div class="loading-container">
                    <kendo-loader type="converging-spinner" size="large"></kendo-loader>
                </div>
            </ng-template>

            <ng-container *ngIf="rowSelectionSettings.canSelectRows">
                <kendo-grid-checkbox-column
                    *appTableAccess="[tableDeleteAccessObj]"
                    [width]="75"
                    showSelectAll="true"
                ></kendo-grid-checkbox-column>
            </ng-container>

            <ng-container *ngIf="rowActionButtons">
                <kendo-grid-column
                    [width]="50"
                >
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="center-align">
                            <button class="row-action-button" (click)="$event.stopPropagation()" mat-mini-fab [matMenuTriggerFor]="settings">
                                <mat-icon
                                    setColor
                                    [setColorRules]="[
                                        {
                                            color: 'primaryColour',
                                            events: ['hover']
                                        }
                                    ]"
                                    class="settings-button__icon material-icons-outlined"
                                >
                                    more_vert
                                </mat-icon>
                            </button>
                            <div class="settings-menu">
                                <mat-menu #settings="matMenu" xPosition="before">
                                    <ng-container *ngFor="let actionButton of rowActionButtons">
                                        <button class="settings-menu__item material-icons-outlined" (click)="$event.stopPropagation(); rowActionEvent.emit({ id:actionButton.id, rowData:dataItem })" mat-menu-item>
                                            <mat-icon class="settings-button__icon material-icons-outlined">{{actionButton.icon}}</mat-icon>
                                            {{actionButton.name}}
                                        </button>
                                    </ng-container>
                                </mat-menu>
                            </div>
                        </div>
                    </ng-template>
                </kendo-grid-column>
            </ng-container>

            <ng-container
                *ngFor="let col of columns"
            >
                <!-- Normal Column Data -->
                <kendo-grid-column
                    *ngIf="!col.type || col.type === 'navigation'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="200">
                    <ng-container *ngIf="defaultColumnTemplate">
                        <ng-template kendoGridHeaderTemplate *ngIf="col.localizationCode">
                            <div appLocalizationPopupDirective [localizationCode]="col.localizationCode">
                                {{ col.localizationCode | translate: {Default: col.title} }}
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="grid-cell-vertical">
                                <ng-container *ngIf="col.subField && dataItem[col.field]; else noSubField">
                                    {{dataItem[col.field][col.subField]}}
                                </ng-container>
                                <ng-template #noSubField>
                                    {{dataItem[col.field]}}
                                </ng-template>
                            </div>
                        </ng-template>
                    </ng-container>
                </kendo-grid-column>

                <!-- Limit Text Length Column Data -->
                <kendo-grid-column
                    *ngIf="col.type === 'longText'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="300">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="grid-cell-vertical">
                            <ng-container *ngIf="col.subField && dataItem[col.field]; else noSubField">
                                {{ (dataItem[col.field][col.subField]?.length > (col.longTextLimit ? col.longTextLimit : 40)) ? (dataItem[col.field][col.subField] | slice:0:(col.longTextLimit ? col.longTextLimit : 40)) + '...' : (dataItem[col.field][col.subField]) }}
                            </ng-container>
                            <ng-template #noSubField>
                                {{ (dataItem[col.field]?.length > (col.longTextLimit ? col.longTextLimit : 40)) ? (dataItem[col.field] | slice:0:(col.longTextLimit ? col.longTextLimit : 40)) + '...' : (dataItem[col.field]) }}
                            </ng-template>
                        </div>
                    </ng-template>
                </kendo-grid-column>


                <kendo-grid-column
                    *ngIf="col.type === 'listCount'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="100">
                    <ng-container *ngIf="defaultColumnTemplate">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="grid-cell-vertical">
                                <ng-container *ngIf="col.subField && dataItem[col.field]; else noSubField">
                                    {{dataItem[col.field][col.subField]?.length ?? 0}}
                                </ng-container>
                                <ng-template #noSubField>
                                    {{dataItem[col.field]?.length ?? 0}}
                                </ng-template>
                            </div>
                        </ng-template>
                    </ng-container>
                </kendo-grid-column>


                <!-- Navigation Link Column Data -->
                <!-- <kendo-grid-column
                    *ngIf="col.type === 'navigation'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="200">
                    <ng-template kendoGridCellTemplate let-dataItem >
                        <div class="grid-cell-vertical">
                            <span class="navigation-link" (click)="emitOpenDialogEvent.emit(dataItem); $event.stopPropagation();">
                                <ng-container *ngIf="col.subField && dataItem[col.field]; else noSubField">
                                    {{dataItem[col.field][col.subField] ? dataItem[col.field][col.subField] : (col.default ? col.default : 'Not provided')}}
                                </ng-container>
                                <ng-template #noSubField>
                                    {{dataItem[col.field] ? dataItem[col.field] : (col.default ? col.default : 'Not provided') }}
                                </ng-template>
                            </span>
                        </div>
                    </ng-template>
                </kendo-grid-column> -->


                <!-- Boolean Column Data -->
                <kendo-grid-column
                    *ngIf="col.type === 'boolean'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="200">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="grid-cell-vertical">
                            <span *ngIf="dataItem[col.field]">
                                {{ col.default[0] ? col.default[0] : dataItem[col.field] }}
                            </span>
                            <span *ngIf="!dataItem[col.field]">
                                {{ col.default[1] ? col.default[1] : dataItem[col.field] }}
                            </span>
                        </div>
                    </ng-template>
                </kendo-grid-column>

                <!-- Chip Column Data -->
                <kendo-grid-column
                    *ngIf="col.type === 'chip'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="200">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="grid-cell-vertical">
                            <mat-chip-list>
                                <ng-container *ngIf="col.subField && dataItem[col.field]; else noSubField">
                                    <mat-chip 
                                        *ngIf="dataItem[col.field][col.subField] !== null"
                                        [ngClass]="
                                            col.chipValue[0] === dataItem[col.field][col.subField] ? 'green-chip' : 
                                            col.chipValue[1] === dataItem[col.field][col.subField] ? 'red-chip' :
                                            col.chipValue[2] === dataItem[col.field][col.subField] ? 'orange-chip' :
                                            col.chipValue[3] === dataItem[col.field][col.subField] ? 'blue-chip' :
                                            ''
                                        "
                                    >
                                        <ng-container *ngIf="col.chipBooleanText; else noChipBooleanText">
                                            <div appLocalizationPopupDirective [localizationCode]="dataItem[col.field][col.subField] === true ? col.chipBooleanText[0] : col.chipBooleanText[1]">
                                                {{ dataItem[col.field][col.subField] === true ? translate.instant(col.chipBooleanText[0]) : translate.instant(col.chipBooleanText[1]) }}
                                            </div>
                                        </ng-container>
                                        <ng-template #noChipBooleanText>
                                            {{ dataItem[col.field][col.subField] }}
                                        </ng-template>
                                    </mat-chip>
                                </ng-container>
                                <ng-template #noSubField>
                                    <mat-chip 
                                        *ngIf="dataItem[col.field] !== null"
                                        [ngClass]="
                                            col.chipValue[0] === dataItem[col.field] ? 'green-chip' : 
                                            col.chipValue[1] === dataItem[col.field] ? 'red-chip' :
                                            col.chipValue[2] === dataItem[col.field] ? 'orange-chip' :
                                            col.chipValue[3] === dataItem[col.field] ? 'blue-chip' :
                                            ''
                                        "
                                    >
                                        <ng-container *ngIf="col.chipBooleanText; else noChipBooleanText">
                                            <div appLocalizationPopupDirective [localizationCode]="dataItem[col.field] === true ? col.chipBooleanText[0] : col.chipBooleanText[1]">
                                                {{ dataItem[col.field] === true ? translate.instant(col.chipBooleanText[0]) : translate.instant(col.chipBooleanText[1]) }}
                                            </div>
                                        </ng-container>
                                        <ng-template #noChipBooleanText>
                                            {{ dataItem[col.field] }}
                                        </ng-template>
                                    </mat-chip>
                                </ng-template>
                            </mat-chip-list>
                        </div>
                    </ng-template>
                </kendo-grid-column>

                <!-- Boolean Toggle Column Data -->
                <kendo-grid-column
                    *ngIf="col.type === 'booleanToggle'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="200">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="grid-cell-vertical">
                            <span *ngIf="dataItem[col.field]">
                                <mat-slide-toggle setColor="primaryColour" [checked]="dataItem[col.field]" disabled="true"></mat-slide-toggle>
                            </span>
                            <span *ngIf="!dataItem[col.field]">
                                <mat-slide-toggle setColor="primaryColour" [checked]="false" disabled="true"></mat-slide-toggle>
                            </span>
                        </div>
                    </ng-template>
                </kendo-grid-column>

                <!-- Date Column Data -->
                <kendo-grid-column
                    *ngIf="col.type === 'date'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="200">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <ng-template kendoGridHeaderTemplate *ngIf="col.localizationCode">
                            <div appLocalizationPopupDirective [localizationCode]="col.localizationCode">
                                {{ col.localizationCode | translate: {Default: col.title} }}
                            </div>
                        </ng-template>
                        <div class="grid-cell-vertical">
                            <span *ngIf="!isDotNotation(col.field)">
                                <div class="grid-cell-vertical">
                                    <ng-container *ngIf="col.subField && dataItem[col.field]; else noSubField">
                                        {{ dataItem[col.field][col.subField] | date:'mediumDate' }}
                                    </ng-container>
                                    <ng-template #noSubField>
                                        {{ dataItem[col.field] | date:'mediumDate' }}
                                    </ng-template>
                                </div>
                            </span>
                            <span *ngIf="isDotNotation(col.field)">
                                {{ resolve(col.field, dataItem) | date:'mediumDate' }}
                            </span>
                        </div>
                    </ng-template>
                </kendo-grid-column>

                <!-- Date Column Data -->
                <kendo-grid-column
                    *ngIf="col.type === 'dateTime'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="200">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="grid-cell-vertical">
                            <span *ngIf="!isDotNotation(col.field)">
                                <div class="grid-cell-vertical">
                                    <ng-container *ngIf="col.subField && dataItem[col.field]; else noSubField">
                                        {{ dataItem[col.field][col.subField] | date:'medium' }}
                                    </ng-container>
                                    <ng-template #noSubField>
                                        {{ dataItem[col.field] | date:'medium' }}
                                    </ng-template>
                                </div>
                            </span>
                            <span *ngIf="isDotNotation(col.field)">
                                {{ resolve(col.field, dataItem) | date:'medium' }}
                            </span>
                        </div>
                    </ng-template>
                </kendo-grid-column>

                <!-- Percentage Column Data -->
                <kendo-grid-column
                    *ngIf="col.type === 'percentage'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="200">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="grid-cell-vertical percentage-column">
                            <span class="percentage-value" *ngIf="dataItem[col.field] !== null">
                                <ng-container *ngIf="col.percentageMaxValue !== undefined; else showValue">
                                    {{ calculateSliderPercentage(dataItem[col.field], col.percentageMaxValue) }}%
                                </ng-container>
                                <ng-template #showValue>
                                    {{ dataItem[col.field] }}%
                                </ng-template>
                            </span>

                            <ng-container *ngIf="col.percentageMaxValue !== undefined; else showProgress">
                                <mat-progress-bar setColor="primaryColour" mode="determinate" [value]="calculateSliderPercentage( dataItem[col.field], col.percentageMaxValue )"></mat-progress-bar>
                            </ng-container>
                            <ng-template #showProgress>
                                <mat-progress-bar setColor="primaryColour" mode="determinate" [value]="dataItem[col.field]"></mat-progress-bar>
                            </ng-template>

                        </div>
                    </ng-template>
                </kendo-grid-column>

                <!-- Employees Grid Column -->
                <kendo-grid-column
                    *ngIf="col.type === 'employees'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="250">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="grid-cell-vertical employee-list" [matTooltip]="showEmployeeTooltip(dataItem[col.field])">
                            <ng-container *ngIf="dataItem[col.field]?.length > 1">
                                <mat-icon
                                    class="material-icons-outlined employee-list-icon"
                                    setColor
                                    [setColorRules]="[
                                        {
                                            color: 'primaryColour',
                                            target: 'mat-badge'
                                        }
                                    ]"
                                    [matBadge]="dataItem[col.field].length">
                                    account_circle
                                </mat-icon>
                            </ng-container>

                            <ng-container *ngIf="dataItem[col.field]?.length == 1">
                                <ng-container *ngFor="let employee of dataItem[col.field]">
                                    <div class="employee-name">
                                        <app-employee-image [employeeId]="employee?.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>

                                        <span class="employee">
                                            {{employee?.firstName}} {{employee?.lastName}}
                                        </span>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-template>
                </kendo-grid-column>

                <!-- Employee Grid Column -->
                <kendo-grid-column
                    *ngIf="col.type === 'employee'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="250"
                    [headerStyle]="{ 'display': 'flex', 'justify-content': 'left' }">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="grid-cell-vertical" *ngIf="dataItem[col.field] !== null">
                            <div class="employee-name">
                                <app-employee-image [employeeId]="dataItem[col.field]?.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>

                                <span class="employee">
                                    {{dataItem[col.field]?.firstName}} {{dataItem[col.field]?.lastName}}
                                </span>
                            </div>
                        </div>
                    </ng-template>
                </kendo-grid-column>

                <!-- Array Grid Column -->
                <kendo-grid-column
                    *ngIf="col.type === 'array'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="250"
                    [headerStyle]="{ 'display': 'flex', 'justify-content': 'left' }">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="grid-cell-vertical array-grid-column">
                            <span *ngFor="let arrayItem of dataItem[col.field]" class="arrayItem">
                                {{arrayItem[col.arrayItemField]}}
                            </span>
                        </div>
                    </ng-template>
                </kendo-grid-column>

                <!-- Colour Grid Column -->
                <kendo-grid-column
                    *ngIf="col.type === 'colour'"
                    [field]="col.field"
                    [title]="col.title"
                >
                    <ng-container *ngIf="defaultColumnTemplate">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="grid-cell-vertical colour-row-item">
                                <ng-container *ngIf="col.subField && dataItem[col.field]; else noSubField">
                                    <div class="colour-display" [style.background]="dataItem[col.field][col.subField]"></div>
                                    {{dataItem[col.field][col.subField]}}
                                </ng-container>
                                <ng-template #noSubField>
                                    <div class="colour-display" [style.background]="dataItem[col.field]"></div>
                                    {{dataItem[col.field]}}
                                </ng-template>
                            </div>
                        </ng-template>
                    </ng-container>
                </kendo-grid-column>

                <!-- Logo Grid Column -->
                <kendo-grid-column
                    *ngIf="col.type === 'logoImage'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="250"
                >
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="grid-cell-vertical">
                            <ng-container *ngIf="col.subField && dataItem[col.field]; else noSubField">
                                <app-logo-image [logoId]="dataItem[col.field][col.subField]" [width]="200" *ngIf="!isLoading"></app-logo-image>
                            </ng-container>
                            <ng-template #noSubField>
                                <app-logo-image [logoId]="dataItem[col.field]" [width]="200" *ngIf="!isLoading"></app-logo-image>
                            </ng-template>
                        </div>
                    </ng-template>
                </kendo-grid-column>
            </ng-container>

            <kendo-grid-column
                *ngIf="accessAndHistoryURL"
                title=""
                [width]="120">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="">
                        <mat-icon
                            *ngIf="showAccessIcon"
                            setColor
                            [setColorRules]="[
                                {
                                    color: 'primaryColour',
                                    events: ['hover']
                                }
                            ]"
                            aria-hidden="false"
                            class="material-icons-outlined k-mr-3"
                            aria-label="add icon"
                            (click)="$event.stopPropagation(); openAccessLogsDialog(dataItem.id)"
                        >
                            fingerprint
                        </mat-icon>

                        <mat-icon
                            *ngIf="showHistoryIcon"
                            setColor
                            [setColorRules]="[
                                {
                                    color: 'primaryColour',
                                    events: ['hover']
                                }
                            ]"
                            aria-hidden="false"
                            class="material-icons-outlined"
                            aria-label="add icon"
                            (click)="$event.stopPropagation(); openHistoryDialog(dataItem.id)"
                        >
                            history
                        </mat-icon>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-excel fileName="{{category}}.xlsx"></kendo-grid-excel>

            <kendo-grid-pdf
                [fileName]='category'
                [allPages]="true"
                paperSize="A4"
                [repeatHeaders]="true"
                [landscape]="true"
            >
                <kendo-grid-pdf-margin
                    top="2cm"
                    left="1cm"
                    right="1cm"
                    bottom="2cm"
                ></kendo-grid-pdf-margin>
                <ng-template
                    kendoGridPDFTemplate
                    let-pageNum="pageNum"
                    let-totalPages="totalPages"
                >
                    <div class="page-template">
                        <div class="header">
                            <div style="float: right">
                                Page {{ pageNum }} of {{ totalPages }}
                            </div>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-pdf>

            <kendo-grid-messages
                [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
                [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')"
                [pagerItems]="translate.instant('Grid-Pager-Items')"
                [pagerOf]="translate.instant('Grid-Pager-Of')"
            ></kendo-grid-messages>

            <ng-container *ngIf="customLoadingIndicator">
                <ng-template kendoGridLoadingTemplate>
                    <div class="k-loading-color"></div>
                    <div class="loading-container">
                        <div>
                            <h1 appLocalizationPopupDirective localizationCode="GeneratingReport">{{ 'GeneratingReport' | translate: {Default: "Generating Report"} }}</h1>
                            <span appLocalizationPopupDirective localizationCode="ThisMayTakeAMinuteOrTwo">{{ 'ThisMayTakeAMinuteOrTwo' | translate: {Default: "This may take a minute or two"} }}...</span>
                            <span>{{customLoadingIndicator.message}}</span>
                            <mat-progress-bar class="my-progress-bar" mode="buffer"></mat-progress-bar>
                        </div>
                    </div>
                </ng-template>
            </ng-container>

        </kendo-grid>
    </mat-card-content>
</mat-card>
