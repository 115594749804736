import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { CellClickEvent, ExcelExportEvent, GridDataResult, PagerSettings, RowArgs, RowClassArgs, SelectableSettings, SelectionEvent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { AccessLogsDialogComponent } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/access-logs-dialog/access-logs-dialog.component';
import { HistoryDialogComponent } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/history-dialog/history-dialog.component';
import { SanitiseGridDataService } from '@app/core/services/sanitise-grid-data/sanitise-grid-data.service';
import { EmployeeTableFieldSecurity, EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { finalize } from 'rxjs/operators';
import { TableField } from '@app/modules/security-setup/models/table-field.model';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { RowActionButtonInput, RowActionEventOutput } from './models/data-grid-comp.model';
import { SortDescriptor } from '@progress/kendo-data-query';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-data-grid-comp',
  templateUrl: './data-grid-comp.component.html',
  styleUrls: ['./data-grid-comp.component.scss']
})
export class DataGridCompComponent implements OnInit {
  @Input() columns: any[];
  @Input() bindingType: String = "array";
  @Input() view: Observable<GridDataResult>;
  @Input() gridData: any;
  @Input() gridDataResult: GridDataResult;
  @Input() isLoading: boolean;
  @Input() pageSize: number = 10;
  @Input() skip: number;
  @Input() category: string;
  @Input() searchable: boolean;
  @Input() historyDialogSettings: {
    showEffectivDateColumn: boolean
  } = {
    showEffectivDateColumn: true
  }
  @Input() accessAndHistoryURL: string;
  @Input() showAccessIcon: boolean = true;
  @Input() showHistoryIcon: boolean = true;
  @Input() parentModelId: string;
  @Input() tableId: string;
  @Input() export: string;
  @Input() clickableRows: boolean;
  @Input() hideActionButtons: boolean;
  @Input() actionButtonText: string;
  @Input() targetEmployeeTablePermissions: EmployeeTableSecurity[];
  @Input() rowActionButtons: RowActionButtonInput[];
  @Input() pageSettings = {
    info: true,
    pageSizes: [5, 10, 20, 50, 100],
    previousNext: true
  };
  @Input() pageable : PagerSettings | boolean = {
      buttonCount: 6,
      info: true,
      type: 'numeric',
      pageSizes: [5, 10, 20, 50, 100],
      previousNext: true
  }


  @Input() rowSelectionSettings: {
    canSelectRows: boolean,
    selectRowBy: any,
    showDeleteAllButton?: boolean,
    selectableMode?: string,
  }
  = {
    canSelectRows: false,
    selectRowBy: 'id',
    showDeleteAllButton: true,
    selectableMode: 'multiple'
  }

  // @Input() canSelectRows: boolean;
  // @Input() selectRowBy: any = 'id';
  @Input() clearSelectedItems: boolean;

  @Input() defaultColumnTemplate: boolean = true;
  @Input() scrollable: any;
  @Input() rowHeight: any;
  @Input() height: any;

  @Input() customLoadingIndicator;

  @Input() sortableColumns: any[];
  @Input() sortable: any;
  @Input() sort: any;

  @Input() filter: any;
  @Input() filterable: any;
  @Input() filterCategories: any[] = [];

  @Input() filterToggleDetails: {
    text: string,
    checked: boolean
  };

  @Input() selectedRowsButtonSettings : {
    show: boolean
    text: string
    icon: string
  } = {
    show: true,
    text: 'DeleteAllSelected',
    icon: 'delete'
  }

  @Input() nonSelectableRows: any[] = [];

  @Input() maxHeight: number = 85;

  @Input() showRefreshButton: boolean;

  @Output() emitPageChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() emitOpenDialogEvent: EventEmitter<any> = new EventEmitter();
  @Output() emitDeleteEvent: EventEmitter<any> = new EventEmitter();
  @Output() emitSearchEvent: EventEmitter<any> = new EventEmitter();
  @Output() emitFilterEvent: EventEmitter<any> = new EventEmitter();
  @Output() emitSortEvent: EventEmitter<any> = new EventEmitter();
  @Output() emitToggleFilter: EventEmitter<MatSlideToggleChange> = new EventEmitter();
  @Output() emitDataStateChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() rowActionEvent: EventEmitter<RowActionEventOutput> = new EventEmitter();
  @Output() emitSelectedRows: EventEmitter<SelectionEvent> = new EventEmitter();
  @Output() emitRefreshEvent: EventEmitter<boolean> = new EventEmitter();
  
  @Input() selectedItems: any[] = [];
  searchValue: string;

  public selectableSettings: SelectableSettings = {
    enabled: false
  };
  sortingColumns: boolean;
  fieldList: TableField[];

  filterToggle: boolean = false;

  constructor(
    private dialog: MatDialog,
    private securitySetupService: SecuritySetupService,
    private tablePermissionsService: TablePermissionsService,
    public translate: TranslateService,
    private sanitiseGridDataService: SanitiseGridDataService
  ) {


  }

  ngOnInit(): void {
    if(this.tableId){
      this.getFields(this.tableId);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.clearSelectedItems){
      this.selectedItems = [];
    }
  }

  get tableReadAccessObj() {
    return {
      tableId: this.tableId,
      permission: 'read',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableCreateAccessObj() {
    return {
      tableId: this.tableId,
      permission: 'create',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableUpdateAccessObj() {
    return {
      tableId: this.tableId,
      permission: 'update',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableDeleteAccessObj() {
    return {
      tableId: this.tableId,
      permission: 'delete',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  // Use an arrow function to capture the 'this' execution context of the class.
  public rowCallback = (context: RowClassArgs) => {
    if (this.clickableRows) {
      return { clickable: true };
    }
  };

  public selectEntireRow = (args: RowArgs) => {
    return args.dataItem;
  };

  public selectionChange(e) {
    if(e !== undefined){

      debugger;
    }
    // if(!this.selectRowBy){
    //   this.selectedItems.push(e.selectedRows[0].dataItem?.id)
    // }
    // else if(this.selectRowBy === 'All'){
    //   this.selectedItems.push(e.selectedRows[0].dataItem)
    // }
    // else {
    //   this.selectedItems.push(e.selectedRows[0].dataItem[this.selectRowBy])
    // }
  }

  cellClickHandler(args: CellClickEvent){
    this.emitOpenDialogEvent.emit(args.dataItem)
  }

  public selectionToggleCallback = (context:RowClassArgs) => {
    let isDisabled = false;

    if(this.nonSelectableRows !== undefined){
      isDisabled = this.nonSelectableRows.includes(context.dataItem?.id);
    }

    return {
      'k-disabled' : isDisabled,
      clickable: this.clickableRows
    };

  }

  getFields(tableId) {
    this.sortingColumns = true;

    this.securitySetupService.getFields(tableId, 0, '200')
    .pipe(
      finalize( () => {
        this.sortingColumns = false;
        this.getTableFieldPermissions();
      })
    )
    .subscribe(
        pagedFieldList => {
          this.fieldList = pagedFieldList.data;

          this.fieldList.forEach(
            field => {
              let index = this.columns.findIndex( column => column.tableId === field.id )
              let filterCategoriesIndex = this.filterCategories.findIndex( column => column.tableId === field.id )

              if(index !== -1){
                if(field.enabled === false){
                  this.columns.splice(index, 1);
                }
                else if(field.name !== null){
                  this.columns[index].title = field.name;
                }
              }

              if(filterCategoriesIndex !== -1){
                if(field.enabled === false){
                  this.filterCategories.splice(filterCategoriesIndex, 1);
                }
                else if(field.name !== null){
                  this.filterCategories[filterCategoriesIndex].title = field.name;
                }
              }
            }
          );
        }
    );
  }

  getTableFieldPermissions() {
    let tableFieldPermissions: EmployeeTableFieldSecurity[] = this.tablePermissionsService.getTableFieldsPermissions(this.tableId, this.targetEmployeeTablePermissions);

    tableFieldPermissions.forEach(
      tableFieldPermission => {
        let index = this.columns.findIndex( column => column.tableId === tableFieldPermission.field.id )

        if(index !== -1){
          if(tableFieldPermission.read === false || tableFieldPermission.deny === true){
            this.columns.splice(index, 1);
          }
        }
      }
    )
  }

  openConfirmDeleteDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      text: `Are you sure you want to delete ${this.selectedItems.length} selected items(s)?`
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.emitDeleteEvent.emit(this.selectedItems);
        }
      }
    );
  }

  openAccessLogsDialog(entityId: string) {
    const dialogConfig = new MatDialogConfig();
    const replacementMap = {
      "[employeeId]": this.parentModelId,
      "[entityId]": entityId,
    };

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      url: this.accessAndHistoryURL.replace(/\[employeeId\]|\[entityId\]/g, (matched) => replacementMap[matched])
    };

    const dialogRef = this.dialog.open(AccessLogsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
        data => {
        }
    );
  }

  openHistoryDialog(entityId: string) {
    const dialogConfig = new MatDialogConfig();
    const replacementMap = {
      "[employeeId]": this.parentModelId,
      "[entityId]": entityId,
    };

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      employeeId: this.parentModelId,
      entityId: entityId,
      url: this.accessAndHistoryURL.replace(/\[employeeId\]|\[entityId\]/g, (matched) => replacementMap[matched]),
      columns: this.columns,
      showEffectivDateColumn: this.historyDialogSettings.showEffectivDateColumn
    };

    const dialogRef = this.dialog.open(HistoryDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
        data => {
        }
    );
  }

  public onExcelExport(e: ExcelExportEvent): void {
    const rows = e.workbook.sheets[0].rows;

    // if the row data is an object create a string of all the values
    rows.forEach((row) => {
      if (row.type === "data") {
        row.cells.forEach((cell) => {
          let val: string = '';

          if(typeof cell.value === 'object' && cell.value !== null) {
            if(cell.value.hasOwnProperty('name')){
              if(val !== ''){
                val = val.concat(', ', val)
              }
              if(cell.value.name !== null){
                val = val.concat(cell.value.name, val)
              }
            }
            else if(cell.value.hasOwnProperty('text')){
              if(val !== ''){
                val = val.concat(', ', val)
              }
              if(cell.value.name !== null){
                val = val.concat(cell.value.text, val)
              }
            }
          }
          else if(moment(cell.value, moment.ISO_8601, true).isValid()) {
            cell.value = moment(cell.value).format('YYYY-MM-DD')
          }

          if(val !== ''){
            cell.value = val;
          }
        });
      }
    });

    e = this.sanitiseGridDataService.sanitise(e);
  }

  public resolve(path, obj) {
      return path.split('.').reduce(function(prev, curr) {
          return prev ? prev[curr] : null
      }, obj || self)
  }

  public isDotNotation(path) {
      return path.indexOf('.') !== -1;
  }

  calculateSliderPercentage(value: string, max: number): number {
    return Number( ((parseFloat(value)/max) * (100/1)).toFixed() );
  }

  showEmployeeTooltip(employees: {id: string, firstName: string, lastName: string}[]) {
    if(employees){
        return employees.map( employee => `${employee.firstName} ${employee.lastName}`).join(', ');
    }

    return null;
  }

  public sortChange(sort: SortDescriptor[]): void {
    let sortString: string = '';

    sort.forEach(
      (sortObj, index) => {

        if(sortObj.dir === undefined){
          sort.splice(index, 1);
        }
        else {
          let sortableColumn = this.sortableColumns.find(col => col.field === sortObj.field)

          if(sortableColumn){
            sortString = this.createSortString(sortableColumn, sortObj, sortString)
          }
          else {
            sort.splice(index, 1);
          }
        }
      }
    )

    this.sort = sort;
    this.emitSortEvent.emit(sortString);
  }

  createSortString(sortableColumn, sort: SortDescriptor, sortString: string): string {
    if (Array.isArray(sortableColumn?.sortValue)) {
      sortableColumn?.sortValue.forEach(
        sortVal => {
          if(sortString !== ''){
            sortString = `${sortString}~${sortVal}-${sort.dir}`;
          }
          else{
            sortString = `${sortVal}-${sort.dir}`;
          }
        }
      )
    } else {
      if(sortString !== ''){
        sortString = `${sortString}~${sortableColumn?.sortValue}-${sort.dir}`;
      }
      else{
        sortString = `${sortableColumn?.sortValue}-${sort.dir}`;
      }
    }

    return sortString;
  }
}
