import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { features, routes } from '@app/consts';
import { FeatureService } from '@app/core/services/feature.service';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { TranslateService } from '@ngx-translate/core';
import { IntegrationsService } from './services/integrations.service';
import { finalize } from 'rxjs/operators';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { defer, forkJoin } from "rxjs";
import { IntegrationSettings } from "@app/modules/site-settings-integrations/models/integrations.model";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

@Component({
    selector: 'app-integrations',
    templateUrl: './integrations.component.html',
    styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent extends SecurityProtectedBase implements OnInit {
    public routes: typeof routes = routes;
    public gridDataResult: GridDataResult;
    public bindingType: String = "array";
    isLoading: boolean;
    pageSize: number = 20;
    skip: number = 0;
    filterString: string;
    public searchFilterString: string;
    getRequest: any;
    clearSelectedItems: boolean = false;
    public searchValue: string;
    private sortString: string = "";

    public columns: any[] = [
        {
            field: 'integration',
            subField: 'displayId',
            title: this.translate.instant('Integrations-Name'),
            localizationCode: 'Integrations-Name',
            dataType: "String"
        },
        {
            field: 'description',
            title: this.translate.instant('Integrations-Description'),
            localizationCode: 'Integrations-Description',
            dataType: "String"
        },
        {
            field: 'status',
            title: this.translate.instant('Integrations-Status'),
            localizationCode: 'Integrations-Status',
            type: 'booleanToggle'
        },
        {
            field: 'version',
            subField: 'createdOn',
            title: this.translate.instant('Integrations-CreatedOn'),
            localizationCode: 'Integrations-CreatedOn',
            type: "dateTime",
        }
    ];

    filterCategories: any[] = [
        {field: "description", title: "Description", type: "String", dataType: 'String'},
    ];

    filterToggleDetails: {
        text: string,
        checked: boolean
    } = {
        text: this.translate.instant('ActiveOnly'),
        checked: false
    }

    constructor(
        private translate: TranslateService,
        private dialog: MatDialog,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        private integrationsService: IntegrationsService,
        private router: Router,
        private route: ActivatedRoute,
        featureService: FeatureService,
    ) {
        super(featureService, features.INTEGRATIONS)
    }

    ngOnInit(): void {
        this.getIntegrations();
    }

    getIntegrations(): void {
        this.isLoading = true;

        let filter;
        if (this.searchFilterString) {
            filter = this.searchFilterString;
        }
        if (this.filterString) {
            if (filter) {
                filter = `${filter} AND ${this.filterString}`;
            } else {
                filter = `${this.filterString}`;
            }
        }

        if (this.filterToggleDetails.checked) {
            if (filter) {
                filter = `(${filter}) AND (Status = "${this.filterToggleDetails.checked}")`;
            } else {
                filter = `(Status = "${this.filterToggleDetails.checked}")`;
            }
        }

        this.getRequest = this.integrationsService.getIntegrations(this.skip, this.pageSize, filter, this.sortString)
        .pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
        .subscribe(
            res => {
                this.gridDataResult = {
                    data: res.data,
                    total: res.total,
                };
            }
        );
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.pageSize = event.take
        this.getIntegrations();
    }

    deleteAllSelected(itemsToDelete: string[]) {
        const observables = itemsToDelete.map(item => defer(() => this.integrationsService.deleteIntegration(item)));

        this.overlayService.show();

        forkJoin(observables)
        .pipe(
          finalize(() => this.overlayService.hide())
        )
        .subscribe(
          (res) => {
            this.clearSelectedItems = !this.clearSelectedItems;
            this.getIntegrations();
            this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
          },
          err => {
              this.snackbarService.openSnackBar(err, 'clear', 'warn');
          }
        );
    }

    filterCallback(filterString: string) {
        this.filterString = filterString;
        this.skip = 0;
        this.getIntegrations();
    }

    updateFilterToggleDetails(toggleChange: MatSlideToggleChange) {
        this.filterToggleDetails.checked = toggleChange.checked;
        this.getIntegrations();
    }

    search(searchValue) {
        this.searchValue = searchValue;
        this.filterString = `(Description like "${this.searchValue}") or (Integration.displayId like "${this.searchValue}")`;
        this.getRequest.unsubscribe();
        this.getIntegrations();
    }

    navigateToIntegration(integration?: IntegrationSettings) {
        if (integration) {
            this.router.navigate([`${routes.SITE_SETTINGS}${routes.INTEGRATIONS}${routes.EDITOR}/${integration?.id}`], {relativeTo: this.route});
        } else {
            this.router.navigate([`${routes.SITE_SETTINGS}${routes.INTEGRATIONS}${routes.EDITOR}`], {relativeTo: this.route});
        }
    }
}
