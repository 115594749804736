import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { EmployeeService } from '@app/core/services/employee.service';
import { DataBindingDirective, GridDataResult, PageChangeEvent, RowClassArgs, SelectionEvent } from '@progress/kendo-angular-grid';
import { finalize } from 'rxjs/operators';
import { SortDescriptor } from "@progress/kendo-data-query";
import { db_tables, miscellaneous, routes } from '@app/consts';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service'
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-select-employees-dialog',
  templateUrl: './select-employees-dialog.component.html',
  styleUrls: ['./select-employees-dialog.component.scss']
})
export class SelectEmployeesDialogComponent implements OnInit {
  @Output() emitSelectedEmployees: EventEmitter<string[]> = new EventEmitter<string[]>();

  public columns: any[] = [
    { field: "employee", title: "Employee", type: 'employee' },
    { field: "preferredName", title: "Preferred Name", tableId: "tfi_PreferredName" },
    { field: "userId", title: "Employee ID", tableId: "tfi_EmpUserId" },
    { field: "customClientId", title: "Custom Client ID", tableId: "tfi_EmployeeId" },
    {field: 'managers', title: this.translate.instant('Managers'), type: 'employees'},
    {field: 'userId', title: 'User ID', tableId: "tfi_EmpUserId" },
    {field: 'positions', title: this.translate.instant('Position'), type: 'array', arrayItemField: 'name'},
    {field: 'organization', title: 'Organisation', subField: 'name', tableId: "tfi_EmpOrganization"},
    {field: 'workLocations', title: this.translate.instant('WorkLocations'), type: 'array', arrayItemField: 'name'},
    {field: 'email', title: 'Email', tableId: "tfi_EmpEmail"},
    {field: 'cellNumber', title: 'Cell Number', tableId: "tfi_EmpCellPhone"},
    {field: 'phoneNumber', title: 'Phone Number', tableId: "tfi_EmpWorkPhone"},
    {field: 'status', title: this.translate.instant('Status'), type: 'chip', chipValue: ['Active']},
    {field: 'subStatus', title: this.translate.instant('SubStatus'), type: 'chip', chipValue: ['Active']},
    {field: 'timezone', subField: 'text', title: 'Timezone', tableId: "tfi_EmpTimezone"},
    {field: 'inOffice', title: this.translate.instant('InOffice'), type: 'booleanToggle'},
    {field: 'office', title: this.translate.instant('Office')},
    {field: 'facebookUrl', title: 'Facebook Url', tableId: "tfi_EmpFacebookUrl"},
    {field: 'linkedInUrl', title: 'LinkedIn Url', tableId: "tfi_EmpLinkedInUrl"},
    {field: 'twitterHandle', title: 'Twitter Handle', tableId: "tfi_EmpTwitterHandle"},
  ];
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public mySelection: string[] = [];
  public routes: typeof routes = routes;
  public miscellaneous: typeof miscellaneous = miscellaneous;
  public sort: SortDescriptor[] = [];
  public db_tables = db_tables;
  pageSize: number = 20;
  skip: number = 0;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  isLoading: boolean;
  filterString: string;
  searchFilterString: string;
  searchValue: string;
  getEmployeesRequest: any;
  sortString: string = 'Lastname-asc~Firstname-asc';

  public filterCategories: any[] = [
    {field: 'firstname', title: 'Firstname', dataType: 'String', tableId: "tfi_FirstName"},
    {field: 'lastname', title: 'Lastname', dataType: 'String', tableId: "tfi_LastName"},
    {field: 'preferredName', title: 'Preferred Name', dataType: 'String', tableId: "tfi_PreferredName"},
    {field: 'userId', title: 'User ID', dataType: 'String', tableId: "tfi_EmpUserId"},
    {field: 'customClientId', title: 'Custom Client ID', dataType: 'String', tableId: "tfi_EmployeeId"},
    {field: 'Position', title: this.translate.instant('Position'), dataType: 'SpecialLookup', lookupCode: 'POSITION'},
    {field: 'Organization', title: this.translate.instant('Organization'), dataType: 'SpecialLookup', lookupCode: 'ORGANISATION'},
    {field: 'WorkLocation', title: this.translate.instant('WorkLocation'), dataType: 'SpecialLookup', lookupCode: 'WORK_LOCATION'},
    {field: 'email', title: 'Email', dataType: 'String', tableId: "tfi_EmpEmail"},
    {field: 'cellNumber', title: 'Cell Number', dataType: 'Number', tableId: "tfi_EmpCellPhone"},
    {field: 'PhoneNumber', title: 'Phone Number', dataType: 'String', tableId: "tfi_EmpWorkPhone"},
    {field: 'SubStatus', title: this.translate.instant('SubStatus'), dataType: 'String'},
    // {field: 'Status', title: 'Status'},
    {field: 'timezone', title: 'Timezone', dataType: 'String', tableId: "tfi_EmpTimezone"},
  ].sort((a, b) => a.field.localeCompare(b.field));

  sortableColumns: any[] = [
    { field: "employee", sortValue: ["Firstname", "Lastname"] },
    { field: "preferredName", sortValue: "preferredName" },
    { field: "userId", sortValue: "userId" },
    { field: "customClientId", sortValue: "customClientId" },
    // {field: 'managers', sortValue: "managers"},
    // {field: 'positions', sortValue: "positions"},
    {field: 'organization', sortValue: "organization"},
    // {field: 'workLocations', sortValue: "workLocations"},
    {field: 'email', sortValue: "email"},
    {field: 'cellNumber', sortValue: "cellNumber"},
    {field: 'phoneNumber', sortValue: "phoneNumber"},
    // {field: 'status', sortValue: "status"},
    // {field: 'subStatus', sortValue: "subStatus"},
    {field: 'timezone', sortValue: "timezone"},
    // {field: 'inOffice', sortValue: "inOffice"},
    // {field: 'office', sortValue: "office"},
    // {field: 'facebookUrl', sortValue: "facebookUrl"},
    // {field: 'linkedInUrl', sortValue: "linkedInUrl"},
    // {field: 'twitterHandle', sortValue: "twitterHandle"},
  ];

  // sort function
  filterToggleDetails: {
    text: string,
    checked: boolean
  } = {
    text: 'FilterInactive',
    checked: true
  }

  selectedRowsButtonSettings : {
    show: boolean
    text: boolean
    icon: string
  } = {
    show: false,
    text: null,
    icon: null
  }

  rowSelectionSettings = {
    canSelectRows: true,
    selectableMode: 'multiple',
    selectRowBy: 'employee'
  }
  
  clearSelectedItems: boolean = false;
  selectedItems: string[] = [];
  nonSelectableEmployees: string[] = [];

  employeeValueToSelect = 'id';
  mandatoryFilter: string;
  // sort function
  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<SelectEmployeesDialogComponent>,
    private employeeContactCardService: EmployeeContactCardService,
    private employeeService: EmployeeService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.nonSelectableEmployees = data.nonSelectableEmployees;
    data.rowSelectionSettings ? this.rowSelectionSettings = data.rowSelectionSettings : null;
    data.employeeValueToSelect ? this.employeeValueToSelect = data.employeeValueToSelect : null;
    data.filter ? this.mandatoryFilter = data.filter : null; 
  }

  ngOnInit(): void {
    this.selectionToggleCallback = this.selectionToggleCallback.bind(this);
    this.view = this.employeeService.getEmployeesKendo();
    this.getEmployees();
  }

  selectionToggleCallback(context:RowClassArgs){
    const isDisabled = this.nonSelectableEmployees.includes(context.dataItem.id);
    return { 'k-disabled' : isDisabled };
  }

  getEmployees(): void {
    this.isLoading = true;

    let filter = this.mandatoryFilter;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.getEmployeesRequest = this.employeeContactCardService.getAllEmployeeContactCards(this.skip, String(this.pageSize), filter, this.sortString, this.filterToggleDetails.checked)
    .pipe(
        finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res.data,
          total: res.total,
        }

        this.formatGridData();
      }
    )
  }

  formatGridData() {
    this.gridDataResult.data.forEach(employeeContactCard => {
      employeeContactCard.employee = {
        id: employeeContactCard.id,
        firstName: employeeContactCard.firstname,
        lastName: employeeContactCard.lastname,
      }
    });
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `((FirstName like "${variant}") OR (LastName like "${variant}") OR (PreferredName like "${variant}") OR (customClientId like "${variant}") OR (userId like  "${variant}"))`;
        }
    });
    this.skip = 0;;
    this.getEmployeesRequest.unsubscribe();
    this.getEmployees();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getEmployees();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getEmployees();
  }

  filterCallback(filterString: string) {
    this.searchFilterString = filterString;
    this.skip = 0;;
    this.getEmployees();
  }

  updateFilterToggleDetails(toggleChange: MatSlideToggleChange){
    this.filterToggleDetails.checked = toggleChange.checked;
    this.getEmployees();
  }

  openConfirmAddUsersDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: `Are you sure you want to add ${this.selectedItems.length} selected User(s)?`
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.emitSelectedEmployees.emit(this.selectedItems.filter(employeeId => !this.nonSelectableEmployees.includes(employeeId)));
        }
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

  selectedRowsChanged(selectionEvent: SelectionEvent){
    // if(this.rowSelectionSettings.selectableMode === "multiple"){
    //   this.selectedItems = selectionEvent.selectedRows.map(row => row.dataItem[this.employeeValueToSelect]);
    // }
    // else if(this.rowSelectionSettings.selectableMode === "single"){
    //   this.selectedItems = [];
    //   if(selectionEvent?.selectedRows[0]?.dataItem){
    //     this.selectedItems.push(selectionEvent.selectedRows[0].dataItem[this.employeeValueToSelect]);
    //   }
    // }

    selectionEvent?.selectedRows?.forEach(selectedRow => {
      this.selectedItems.push(selectedRow.dataItem[this.employeeValueToSelect]);
    });

    selectionEvent?.deselectedRows?.forEach(deselectedRow => {
        this.selectedItems = this.selectedItems.filter(item => item !== deselectedRow.dataItem[this.employeeValueToSelect]);
      }
    );
  }
}

