<div class="integration-tab-container">
    <div>
        <div class="form-section-title"><h6>iCIMS</h6></div>
    </div>

    <mat-tab-group [(selectedIndex)]="selectedTabIndex">
        <mat-tab [label]="translate.instant('Integrations-Details')">
            <app-integration-details-tab></app-integration-details-tab>
        </mat-tab>
        <mat-tab [label]="translate.instant('Integrations-Mappings')">
        <app-integration-mappings-tab [integrationId]="integration.id"></app-integration-mappings-tab>
        </mat-tab>
        <mat-tab [label]="translate.instant('Integrations-Logs')">
            <app-integration-logs-tab [integrationId]="integration.id"></app-integration-logs-tab>
        </mat-tab>
    </mat-tab-group>
</div>
