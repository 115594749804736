import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LettersService } from '@app/modules/letters/services/letters.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize, forkJoin } from 'rxjs';
import { EmployeeSecurityService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/services/employee-security.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { LetterFooterVerbose, LetterheadVerbose, LetterTemplateVerbose, TemplateOrganization, TemplateOwner, TemplatePublisher } from '@app/modules/letters/models/letters.model';

@Component({
  selector: 'app-preview-template-dialog',
  templateUrl: './preview-template-dialog.component.html',
  styleUrls: ['./preview-template-dialog.component.scss']
})
export class PreviewTemplateDialogComponent implements OnInit {
  title: string;
  templateId: string;
  loadingTemplate: boolean;
  letterTemplate: LetterTemplateVerbose;
  error: boolean;
  loadingOrganizations: boolean;
  letterOwners: TemplateOwner[];
  letterPublishers: TemplatePublisher[];
  letterOrganizations: TemplateOrganization[];
  step1FormData: any;
  step1HtmlFormData: any;
  step2FormData: any;
  letterheadDetails: LetterheadVerbose;
  letterFooterDetails: LetterFooterVerbose;
  loadingLetterhead: boolean;
  loadingLetterFooter: boolean;
  loadingCultures: boolean;
  currentCulture: Culture;

  constructor(
    public translate: TranslateService,
    public lettersService: LettersService,
    private dialogRef: MatDialogRef<PreviewTemplateDialogComponent>,
    private employeeSecurityService: EmployeeSecurityService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.templateId = data.templateId;
  }

  ngOnInit(): void {
    this.getCurrentCulture();
  }

  getCurrentCulture() {
    this.loadingCultures = true;

    this.employeeSecurityService.getCurrentEmployeeCulture()
    .pipe(
      finalize(() => this.loadingCultures = false)
    )
    .subscribe(
      res => {
        this.currentCulture = res;
        this.getLetterTemplate(this.templateId);
      }
    )
  }

  /**
   * Retrieves a letter template by its ID.
   * 
   * @param id - The ID of the letter template to retrieve.
   */
  getLetterTemplate(id: string) {
    this.loadingTemplate = true;
    // this.overlayService.show('Loading...');

    this.lettersService.getLetterTemplate(id)
    .subscribe(
      {
        next: (v) => {
          this.letterTemplate = v;
          this.buildStep1FormData();
          this.getTemplateOrganizations(id);
        },
        error: (e) => {
          this.error = true,
          this.loadingTemplate = false
        },
        complete: () => this.loadingTemplate = false
      }
    );
  }

  /**
   * Retrieves the template organizations for a given template ID.
   * 
   * @param id - The ID of the template.
   */
  getTemplateOrganizations(id: string) {
    this.loadingOrganizations = true;
    // this.overlayService.show('Loading...');

    forkJoin([
      this.lettersService.getTemplateOwners(100, 0, null, `(template.id = "${id}")`),
      this.lettersService.getTemplatePublishers(100, 0, null, `(template.id = "${id}")`),
      this.lettersService.getTemplateOrganizations(100, 0, null, `(template.id = "${id}")`)
    ])
    .subscribe(
      {
        next: (v) => {
          this.letterOwners = v[0].data;
          this.letterPublishers = v[1].data;
          this.letterOrganizations = v[2].data;
          this.buildStep2FormData();
        },
        error: (e) => {
          this.error = true,
          this.loadingOrganizations = false
        },
        complete: () => {
          this.loadingOrganizations = false
          this.getLetterheadDetails();
          this.getFooterDetails();
        }
      }
    );
  }

  buildStep1FormData() {
    debugger;
    this.step1FormData = {
      id: this.letterTemplate?.id || null,
      name: this.letterTemplate?.name.filter( n => n.culture === this.currentCulture.id ) || null,
      description: this.letterTemplate?.description.filter( n => n.culture === this.currentCulture.id ) || null,
      letterhead: this.letterTemplate?.letterHead?.id || null,
      footer: this.letterTemplate?.footer?.id || null,
      status: this.letterTemplate?.status?.id || null,
      topMargin: this.letterTemplate?.topMargin || 0,
      bottomMargin: this.letterTemplate?.bottomMargin || 0,
      pageMargin: this.letterTemplate?.pageMargin || 0,
    }

    this.step1HtmlFormData = {
      html: this.letterTemplate?.html || null,
    }
  }
  
  /**
   * Builds the form data for step 2 of the letter template editor.
   * @returns {void}
   */
  buildStep2FormData() {
    this.step2FormData = {
      status: this.letterTemplate?.status?.id || null,
      sendToActiveUsersOnly: this.letterTemplate?.sendToActiveUsersOnly || false,
      abilityToSetTags: this.letterTemplate?.abilityToSetTags || false,
      owners: this.letterOwners.map(x => x.owner) || null,
      publishers: this.letterPublishers.map(x => x.publisher) || null,
      organizations: this.letterOrganizations.map(x => x.organization) || null,
    }
  }

  getLetterheadDetails() {
    this.loadingLetterhead = true

    this.lettersService.getLetterhead(this.step1FormData.letterhead)
    .subscribe(
      {
        next: (v) => {
          this.letterheadDetails = v;
          this.loadingLetterhead = false
        },
        error: (e) => {
          this.error = true;
          this.loadingLetterhead = false;
        }
      }
    );
  }

  getFooterDetails() {
    this.loadingLetterFooter = true;
    
    this.lettersService.getLetterFooter(this.step1FormData.footer)
    .subscribe(
      {
        next: (v) => {
          this.letterFooterDetails = v;
          this.loadingLetterFooter = false;
        },
        error: (e) => {
          this.error = true;
          this.loadingLetterFooter = false;
        }
      }
    );
  }

  close() {
    this.dialogRef.close();
  }
}
