import { Component, Input } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-integration-tab-container',
    templateUrl: './integration-tab-container.component.html',
    styleUrls: ['./integration-tab-container.component.scss']
})
export class IntegrationTabContainerComponent {
    @Input() integration: any;

    selectedTab: string = 'details';
    selectedTabIndex: number = 0;

    constructor(
        public translate: TranslateService,
    ) {
    }

    selectTab(tab: string) {
        this.selectedTab = tab;
    }
}
