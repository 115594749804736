import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IntegrationSettings } from '../models/integrations.model';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
    'Content-Type': 'application/json',
});

@Injectable({
    providedIn: 'root'
})
export class IntegrationsService {

    constructor(private http: HttpClient, private envService: EnvironmentService) {
    }

    getIntegrationTypes(skip?: number, take?: number): Observable<PagedData<IntegrationSettings>> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;

        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations`, {
            headers: headers,
            params: params
        })
    }


    getIntegrations(skip?: number, take?: number, filterString?: string, sort?: string): Observable<PagedData<IntegrationSettings>> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;
        filterString ? params = params.append('Filter', filterString) : null;
        sort ? params = params.append('sort', sort) : null;

        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/Settings`, {
            headers: headers,
            params: params
        })
    }

    getIntegration(id: string): Observable<IntegrationSettings> {
        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}`, {
            headers: headers
        })
    }

    deleteIntegration(id: string): Observable<any> {
        return this.http
        .delete<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}`, {
            headers: headers
        })
    }

    createIntegration(integrationPayload): Observable<any> {
        return this.http
        .post<any>(`${this.envService.env.apiUrl}Integrations/Settings`, integrationPayload, {
            headers: headers
        })
    }

    updateIntegration(id: string, integrationPayload): Observable<any> {
        return this.http
        .put<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}`, integrationPayload, {
            headers: headers
        })
    }


    getIntegrationConfig(): Observable<any> {
        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/Config`, {
            headers: headers
        })
    }

    updateIntegrationConfig(integrationConfigPayload): Observable<any> {
        return this.http
        .post<any>(`${this.envService.env.apiUrl}Integrations/Config`, integrationConfigPayload, {
            headers: headers
        })
    }

    getIntegrationEvents(integrationId: any, skip?: number, take?: number): Observable<any> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;

        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/Events?IntegrationId=${integrationId}`, {
            headers: headers,
            params: params
        })
    }

    getIntegrationEventLogs(id: string, skip?: number, take?: number): Observable<any> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : 0;
        take ? params = params.append('take', take) : 100;

        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/Logs?integrationEventId=${id}`, {
            headers: headers,
            params: params
        })
    }

    getIntegrationMappings(integrationId: any, skip?: number, take?: number): Observable<any> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;

        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/${integrationId}/RecordMappings`, {
            headers: headers,
            params: params
        })
    }

    createIntegrationMapping(integrationId: any, mappingPayload): Observable<any> {
        return this.http
        .post<any>(`${this.envService.env.apiUrl}Integrations/${integrationId}/RecordMappings`, mappingPayload, {
            headers: headers
        })
    }

    updateIntegrationMapping(integrationId: any, mappingId: any, mappingPayload): Observable<any> {
        return this.http
        .put<any>(`${this.envService.env.apiUrl}Integrations/${integrationId}/RecordMappings/${mappingId}`, mappingPayload, {
            headers: headers
        })
    }

    deleteIntegrationMapping(integrationId: any, mappingId: any): Observable<any> {
        return this.http
        .delete<any>(`${this.envService.env.apiUrl}Integrations/${integrationId}/RecordMappings/${mappingId}`, {
            headers: headers
        })
    }


}
